import React, { useState } from 'react';

import { APP_STORE_URL, PLAY_STORE_URL } from '@constants/links';
import StyleSheet from '@styles/TypedStyleSheet';
import { isAndroid, isIOS } from 'react-device-detect';
import { Navigate, useOutlet } from 'react-router-dom';

import Footer from './Footer/Footer';
import QRModal from './QRModal/QRModal';
import TopNav from './TopNav/TopNav';

const styles = StyleSheet.create({
  mainContainer: {
    paddingTop: '115px',
    backgroundColor: '#F9FAFB',
  },
});

const PageLayout: React.FC = () => {
  const outlet = useOutlet();
  const [modalShow, setModalShow] = useState(false);

  // TODO: add analytics here for sign ups
  const handleModalCTAClick = () => {
    if (isAndroid) {
      window.location.assign(PLAY_STORE_URL);
    } else if (isIOS) {
      window.location.assign(APP_STORE_URL);
    } else {
      setModalShow(true);
    }
  };
  if (!outlet) {
    return <Navigate to='/error' replace />;
  }
  return (
    <div>
      <TopNav ctaClick={handleModalCTAClick} />
      <main style={styles.mainContainer}>{outlet}</main>
      <Footer ctaClick={handleModalCTAClick} />
      <QRModal show={modalShow} onHide={() => setModalShow(false)} id='qr-modal' size='sm' />
    </div>
  );
};

export default PageLayout;
