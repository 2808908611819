import Axios from 'axios';
import type { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';

export type AxiosInstanceReplacedListener = (instance: AxiosInstance) => void;

export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.REACT_APP_SYCAMORE_BASE_URL });

export const client = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token })
    .then(({ data }) => data)
    .catch((e: AxiosError | Error) => {
      if ('isAxiosError' in e && e.isAxiosError && e.response?.data) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw Object.assign(e, e.response.data);
      }
      throw e;
    });

  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Promise<T> {
    cancel?(): void;
  }
}

export type ErrorType<ServerError> = AxiosError<void> & (ServerError | void);

export default client;
