import React from 'react';

import ShareMenu from '@components/ShareMenu';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { format } from 'date-fns';

interface NameHeaderProps {
  userName: string;
  memberSince: Date | undefined;
  urlToShare: URL;
}

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  memberName: {
    fontFamily: fonts.semiBold,
    color: colors.text.charcoal,
  },
  subtitle: {
    fontFamily: fonts.semiBold,
    color: colors.text.lighterGray,
  },
});
const mediumScreenStyles = StyleSheet.create({
  memberName: {
    fontSize: '30px',
    lineHeight: '34.8px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '18.56px',
    marginTop: '10px',
  },
});
const smallScreenStyles = StyleSheet.create({
  memberName: {
    fontSize: '20px',
    lineHeight: '23.2px',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '16.24px',
    marginTop: '8px',
  },
});

const messages = {
  memberSince: `Member since `,
};

const NameHeader = ({ userName, memberSince, urlToShare }: NameHeaderProps) => {
  const isMediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  const formattedDate = memberSince ? format(memberSince, 'MM/dd/yyyy') : '';
  return (
    <div style={styles.mainContainer}>
      <div className='d-flex flex-column'>
        <span
          style={{
            ...styles.memberName,
            ...(isMediumScreen ? mediumScreenStyles.memberName : smallScreenStyles.memberName),
          }}
        >
          {userName}
        </span>
        <span
          style={{
            ...styles.subtitle,
            ...(isMediumScreen ? mediumScreenStyles.subtitle : smallScreenStyles.subtitle),
          }}
        >
          <>
            {messages.memberSince}
            {formattedDate}
          </>
        </span>
      </div>
      <ShareMenu urlToShare={urlToShare} />
    </div>
  );
};

export default NameHeader;
