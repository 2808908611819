import React from 'react';

import QRCodeImg from '@assets/images/qr-modal/qr-code.png';
import breaks from '@constants/breaks';
import { APP_STORE_URL, PLAY_STORE_URL } from '@constants/links';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import type { ModalProps } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import './styles.css';

import Icon from '../Icon';

const messages = {
  title: 'Get the app',
};

const styles = StyleSheet.create({
  title: {
    fontSize: '30px',
    marginTop: '20px',
    marginBottom: '10px',
    lineHeight: '120%',
    fontWeight: '700',
  },
  appBtn: {
    height: '62px',
    paddingLeft: '10px',
    paddingRight: '10px',
    border: '1px solid #000',
    borderRadius: '6px',
  },
});

const mediumScreenStyles = StyleSheet.create({
  title: {
    fontSize: '38px',
  },
});

const largeScreenStyles = StyleSheet.create({
  title: {
    fontSize: '48px',
  },
});

const QRModal = (props: ModalProps) => {
  const mediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  const largeScreen = useMediaQuery(`(min-width: ${breaks.large}px)`);

  return (
    <Modal {...props} size='sm' aria-labelledby={props.id} centered>
      <Modal.Header className='qr-modal' closeButton>
        <Modal.Title className='mx-auto' id={props.id}>
          <h2
            className='primary-forest font-bold'
            style={{
              ...styles.title,
              ...(mediumScreen ? mediumScreenStyles.title : {}),
              ...(largeScreen ? largeScreenStyles.title : {}),
            }}
          >
            {messages.title}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-lg-block d-none'>
        <Image src={QRCodeImg} alt='QR image for Treecard app' className='d-block mx-auto' />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between flex-column flex-md-row'>
        <a
          href={APP_STORE_URL}
          style={styles.appBtn}
          className='d-flex align-items-center justify-content-center app-link mb-3'
          data-testid='downloadAppStore'
        >
          <Icon name='downloadAppStore' width={150} />
        </a>

        <a
          href={PLAY_STORE_URL}
          style={styles.appBtn}
          className='d-flex align-items-center justify-content-center app-link mb-3'
          data-testid='downloadGooglePlay'
        >
          <Icon name='downloadGooglePlay' width={150} />
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default QRModal;
