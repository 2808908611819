/* eslint-disable max-len */

// WEBSITE NAVIGATION
export const TREECARD_HOME_URL = 'https://www.treecard.org/';
export const TREECARD_MISSION_URL = 'https://www.treecard.org/the-mission';
export const TREECARD_IMPACT_URL = 'https://www.treecard.org/impact';
export const TREECARD_APP_URL = 'https://treecard.app.link/2KdNzq2uefb';
export const TREECARD_PRIVACY_URL =
  'https://assets.website-files.com/5f16d530791d4326e79cd1b0/60f9a064c3cced8a2bf47430_TC%20Privacy%20Policy%201%20June%202021%20Clean%20(2).docx.pdf';
export const TREECARD_HOW_IT_WORKS_URL = 'https://www.treecard.org/how-it-works';
export const TREECARD_CONTACT_URL = 'mailto:hello@treecard.org';
export const TREECARD_BLOG_URL = 'https://www.treecard.org/blog';
export const TREECARD_WALK_TO_PLANT_URL = 'https://www.treecard.org/walk-to-plant';
export const TREECARD_PETITIONS_URL = 'https://www.treecard.org/petitions';

// APP
export const APP_STORE_URL = 'https://apps.apple.com/us/app/treecard/id1556120981';
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=org.treecard.app&amp;hl=en_GB&amp;gl=US';

// SOCIAL MEDIA
export const TWITTER_URL = 'https://twitter.com/TreeCardApp';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/treecardapp/';
export const FACEBOOK_URL = 'https://www.facebook.com/treecardapp';
export const INSTAGRAM_URL = 'https://www.instagram.com/treecard/';
