import React from 'react';

import ImpactCarousel from '@components/ImpactCarousel/ImpactCarousel';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { Col, Row } from 'react-bootstrap';

const styles = StyleSheet.create({
  subtitle: {
    fontFamily: fonts.semiBold,
    color: colors.text.lighterGray,
  },
});
const mediumScreenStyles = StyleSheet.create({
  subtitle: {
    fontSize: '18px',
    lineHeight: '20.88px',
    marginBottom: '30px',
  },
  carouselContainer: {
    marginTop: '30px',
  },
});
const smallScreenStyles = StyleSheet.create({
  subtitle: {
    fontSize: '14px',
    lineHeight: '16.24px',
    marginBottom: '15px',
  },
  carouselContainer: {
    marginTop: '15px',
  },
});

const messages = {
  partners: `Treecard’s impact partners`,
};

const PartnersRow = () => {
  const isMediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  return (
    <Row className={isMediumScreen ? 'mt-50' : 'mt-30'} data-testid='PartnersRow'>
      <Col xs={12}>
        <span
          style={{
            ...styles.subtitle,
            ...(isMediumScreen ? mediumScreenStyles.subtitle : smallScreenStyles.subtitle),
          }}
        >
          {messages.partners}
        </span>
        <div
          style={
            isMediumScreen
              ? mediumScreenStyles.carouselContainer
              : smallScreenStyles.carouselContainer
          }
        >
          <ImpactCarousel />
        </div>
      </Col>
    </Row>
  );
};

export default PartnersRow;
