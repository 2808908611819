import React, { useState } from 'react';

import Icon from '@components/Icon';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { isMobile } from 'react-device-detect';
import OutsideClickHandler from 'react-outside-click-handler';

import SquareButton from './SquareButton';

interface ShareMenuProps {
  urlToShare: URL;
}

const styles = StyleSheet.create({
  mainContainer: {
    position: 'relative',
  },
  popup: {
    position: 'absolute',
    top: 54,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 100,
  },
  menuContainer: {
    borderRadius: 6,
    backgroundColor: colors.forest,
    padding: '15px 9px',
    display: 'flex',
    flexDirection: 'row',
  },
  pointer: {
    borderRadius: 4,
    width: 21,
    height: 21,
    backgroundColor: colors.forest,
    transform: 'rotate(45deg)',
    position: 'absolute',
    top: -6,
    right: 12,
  },
  socialButton: {
    margin: '0 9px',
  },
  copiedText: {
    fontFamily: fonts.bold,
    fontSize: '9px',
    lineHeight: '10.88px',
    color: colors.white,
  },
});

const messages = {
  copied: 'Copied!',
  emailSubject: `Check out the impact I've made!`,
};

const ShareMenu = ({ urlToShare }: ShareMenuProps) => {
  const isMediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  const [showMenu, setShowMenu] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const composeTweet = () => {
    window.open(`https://twitter.com/share?url=${encodeURI(urlToShare.toString())}`);
  };
  const composeFb = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(urlToShare.toString())}`);
  };
  const composeWhatsapp = () => {
    window.open(`https://web.whatsapp.com/send?text=${encodeURI(urlToShare.toString())}`);
  };
  const composeEmail = () => {
    window.open(
      `mailto:?subject=${encodeURI(messages.emailSubject)}&body=${encodeURI(
        urlToShare.toString(),
      )}`,
    );
  };

  const openMenu = () => {
    if (isMobile) {
      try {
        navigator.share({ url: urlToShare.toString() });
      } catch (err) {
        console.log('share error:', err);
      }
    } else {
      setShowMenu(!showMenu);
    }
  };
  const closeMenu = () => {
    setShowMenu(false);
    setCopiedToClipboard(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlToShare.toString());
    setCopiedToClipboard(true);
  };

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <div style={styles.mainContainer}>
        <SquareButton onClick={openMenu} selected={showMenu} size={isMediumScreen ? 45 : 40}>
          <Icon
            name='share'
            fill={showMenu ? colors.white : colors.success}
            width={isMediumScreen ? 20 : 16}
          />
        </SquareButton>
        {showMenu ? (
          <div style={styles.popup}>
            <div style={styles.pointer} />
            <div style={styles.menuContainer}>
              <SquareButton style={styles.socialButton} onClick={composeTweet}>
                <Icon name='twitter' fill={colors.success} />
              </SquareButton>
              <SquareButton style={styles.socialButton} onClick={composeFb}>
                <Icon name='facebook' fill={colors.success} />
              </SquareButton>
              <SquareButton style={styles.socialButton} onClick={composeWhatsapp}>
                <Icon name='whatsapp' fill={colors.success} />
              </SquareButton>
              <SquareButton style={styles.socialButton} onClick={composeEmail}>
                <Icon name='mail' fill={colors.success} />
              </SquareButton>
              <SquareButton
                style={styles.socialButton}
                onClick={copyToClipboard}
                selected={copiedToClipboard}
              >
                {copiedToClipboard ? (
                  <span style={styles.copiedText}>{messages.copied}</span>
                ) : (
                  <Icon name='link' fill={colors.success} />
                )}
              </SquareButton>
            </div>
          </div>
        ) : null}
      </div>
    </OutsideClickHandler>
  );
};

export default ShareMenu;
