import React from 'react';

import { useGetPublicProfile } from '@api/sycamore';
import { parseISO } from 'date-fns';
import { Container } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';

import MapRow from './MapRow';
import PartnersRow from './PartnersRow';
import StatRow from './StatRow';

const UserImpact = () => {
  const { id } = useParams();
  const { data: userData, isError } = useGetPublicProfile(id ?? '');
  if (isError) {
    return <Navigate to='/error' replace />;
  }

  const userStats = {
    trees: userData?.impactStats.treesPlantedCount ?? 0,
    carbon: (userData?.impactStats.carbonSequesteredPounds ?? 0) / 2000,
    bottles: userData?.impactStats.plasticRecoveredBottleCount ?? 0,
    animals: userData?.impactStats.animalsAdoptedCount ?? 0,
  };

  return (
    <Container className='overflow-hidden'>
      <StatRow
        firstName={userData?.firstName ?? ''}
        lastName={userData?.lastName ?? ''}
        memberSince={userData?.createdAt ? parseISO(userData.createdAt) : undefined}
        userStats={userStats}
        tvImageUrl={userData?.screenshotURL}
      />
      <PartnersRow />
      <MapRow />
    </Container>
  );
};

export default UserImpact;
