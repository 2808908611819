/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Sycamore APIs
 * # Sycamore API specification
 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';

import type {
  EmptyResponseResponse,
  SycamoreErrorResponseResponse,
  DiscoverCommunityUpdatesResponseResponse,
  GetDiscoverCommunityUpdatesParams,
  HowItWorksLinksResponseResponse,
  PetitionsResponseResponse,
  WalkToPlantSponsorResponseResponse,
  ExpectedAchClearanceDateResponseResponse,
  ExpectedAchClearanceTime,
  ArgyleUserResponseResponse,
  CreatePasswordUserOutputResponse,
  CreatePasswordUserBody,
  UserResponseResponse,
  GoldenTicketCodePayload,
  HomeAddressOutputResponse,
  HomeAddressPayload,
  CreateKycTokenResponseResponse,
  PersonalDetailsOutputResponse,
  PersonalDetailsPayload,
  SetStatusRequest,
  VerificationIDStatusResponseResponse,
  UploadVerificationIDRequest,
  VerifySSNRequest,
  WaitlistQuestionnaireOutputResponse,
  WaitlistQuestionnaire,
  UpdateUserPayload,
  GetUserBankAccountsResponseResponse,
  AddNewBankAccountToCardResponseResponse,
  AddNewBankAccountToCardRequestBody,
  BonusOffersResponseResponse,
  UpdateBonusOfferPayload,
  CardsResponseResponse,
  TransactionsResponseResponse,
  GetPhysicalAndVirtualTransactionsParams,
  AddFundsToCardResponseResponse,
  AddFundsToCardRequest,
  AddFundsToCardPromptResponseResponse,
  AddFundsToCardPromptRequest,
  GetCardAuthDataResponseResponse,
  GetBalanceResponseResponse,
  GetCardBankDetailsResponseResponse,
  CancelPreAuthRequest,
  SetCharityDonationPayload,
  GetCardDeliveryStatusResponseResponse,
  InitializeFirstTimeCardPaymentResponseResponse,
  InitializeFirstTimeCardPaymentRequest,
  GetCardPINResponseResponse,
  SetCardPinRequest,
  SendFundsRequest,
  GetCardStatusResponseResponse,
  GetTransactionsParams,
  GenerateWalletProviderDataResponseResponse,
  GenerateWalletProviderDataPayload,
  WithdrawFundsRequest,
  CheckContactResponseResponse,
  CheckContactPayload,
  UserConfigResponseResponse,
  SetUserConfigPayload,
  DiscoverNotificationsResponseResponse,
  DiscoverNotificationResponseResponse,
  DiscoverTopUpdatesResponseResponse,
  GetEarnPointOffersResponseResponse,
  CreateEventResponseResponse,
  EventPayload,
  UserFeatureFlagsResponseResponse,
  UserFundingCardsResponseResponse,
  GiftRewardTransactionsResponseResponse,
  ClaimGiftRewardRequest,
  ImpactStatsResponseResponse,
  ListUserImpactStoriesResponseResponse,
  GetUserImpactStoryResponseResponse,
  UserImpactStory,
  GetIterableJwtResponseResponse,
  UserLimitsResponseResponse,
  CreateLinkTokenResponseResponse,
  CreateLinkTokenParams,
  MyTreesUpdatesResponseResponse,
  UpdateUserPasswordPayload,
  PayWhatsFairResponseResponse,
  SetPayWhatsFairPayload,
  GetPaymentIntentErrorResponseResponse,
  CanUserSkipPlaidResponseResponse,
  PlantTreesBody,
  PotSummaryResponseResponse,
  PotResponseResponse,
  CreatePotBody,
  PotDetailsResponseResponse,
  UpdatePotBody,
  PotTransfer,
  PotTransactionsResponseResponse,
  PotWithdrawFundsBody,
  NotificationPreferencesResponseResponse,
  NotificationPreferences,
  ClaimReferralRewardRequest,
  GetUserReferralTiersResponseResponse,
  GetUserReferralTiersParams,
  UserReferralsResponseResponse,
  UserReferrerResponseResponse,
  ClaimRewardRequest,
  ClaimableRewardsResponseResponse,
  RewardPointsResponseResponse,
  RewardTransactionsResponseResponse,
  GetStatementPdfResponseResponse,
  GetStatementPdfParams,
  UserStatementPeriodsResponseResponse,
  GetTopupPreferencesResponseResponse,
  SetTopupPreferencesResponseResponse,
  SetTopupPreferencesPayload,
  TransactionsSpendSummaryResponseResponse,
  GetTransactionsSpendSummaryParams,
  TransactionDetailsResponseResponse,
  TreesPlantedResponseResponse,
  GetTreesPlantedParams,
  PatchZendeskPayload,
  GetZendeskJwtResponseResponse,
  CreateTreeValleyScreenshotResponseResponse,
  TreeValleyStateResponseResponse,
  TreeValleyTilesResponseResponse,
  UnityTreeValleyTile,
  TreeValleyUnclaimedPointsResponseResponse,
  ListMapLocationsResponseResponse,
  PublicProfile,
  ValidateReferalCodePayload,
  GetRewardsResponseResponse,
  GetRewardsParams,
  GetRewardTeasersResponseResponse,
  CommunityImpactStatsResponseResponse,
  DiscoverImpactStatsResponseResponse,
  GetDiscoverImpactStatsParams,
  GlobalTreesPlantedResponseResponse,
  TreePlantingExchangeRateResponseResponse,
} from './models';
import { client } from './mutator/client';
import type { ErrorType } from './mutator/client';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Cancels the specified gift transaction
 */
export const cancelGiftReward = () => {
  return client<EmptyResponseResponse>({
    url: `/admin/users/giftedRewards/cancel`,
    method: 'post',
  });
};

export type CancelGiftRewardMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelGiftReward>>
>;

export type CancelGiftRewardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCancelGiftReward = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelGiftReward>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelGiftReward>>,
    TVariables
  > = () => {
    return cancelGiftReward();
  };

  return useMutation<Awaited<ReturnType<typeof cancelGiftReward>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary Get community updates for discover tab
 */
export const getDiscoverCommunityUpdates = (
  params?: GetDiscoverCommunityUpdatesParams,
  signal?: AbortSignal,
) => {
  return client<DiscoverCommunityUpdatesResponseResponse>({
    url: `/content/discover/community`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetDiscoverCommunityUpdatesQueryKey = (
  params?: GetDiscoverCommunityUpdatesParams,
) => [`/content/discover/community`, ...(params ? [params] : [])];

export type GetDiscoverCommunityUpdatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDiscoverCommunityUpdates>>
>;
export type GetDiscoverCommunityUpdatesQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetDiscoverCommunityUpdates = <
  TData = Awaited<ReturnType<typeof getDiscoverCommunityUpdates>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetDiscoverCommunityUpdatesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDiscoverCommunityUpdates>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscoverCommunityUpdatesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiscoverCommunityUpdates>>> = ({
    signal,
  }) => getDiscoverCommunityUpdates(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDiscoverCommunityUpdates>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get how it works content
 */
export const getHowItWorksLinks = (signal?: AbortSignal) => {
  return client<HowItWorksLinksResponseResponse>({
    url: `/content/howItWorksLinks`,
    method: 'get',
    signal,
  });
};

export const getGetHowItWorksLinksQueryKey = () => [`/content/howItWorksLinks`];

export type GetHowItWorksLinksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHowItWorksLinks>>
>;
export type GetHowItWorksLinksQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetHowItWorksLinks = <
  TData = Awaited<ReturnType<typeof getHowItWorksLinks>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getHowItWorksLinks>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHowItWorksLinksQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHowItWorksLinks>>> = ({ signal }) =>
    getHowItWorksLinks(signal);

  const query = useQuery<Awaited<ReturnType<typeof getHowItWorksLinks>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get list of petitions
 */
export const getPetitions = (signal?: AbortSignal) => {
  return client<PetitionsResponseResponse>({ url: `/content/petitions`, method: 'get', signal });
};

export const getGetPetitionsQueryKey = () => [`/content/petitions`];

export type GetPetitionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPetitions>>>;
export type GetPetitionsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetPetitions = <
  TData = Awaited<ReturnType<typeof getPetitions>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPetitions>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPetitionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPetitions>>> = ({ signal }) =>
    getPetitions(signal);

  const query = useQuery<Awaited<ReturnType<typeof getPetitions>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get community updates for discover tab
 */
export const getWalkToPlantSponsor = (signal?: AbortSignal) => {
  return client<WalkToPlantSponsorResponseResponse>({
    url: `/content/walktoplant/sponsor`,
    method: 'get',
    signal,
  });
};

export const getGetWalkToPlantSponsorQueryKey = () => [`/content/walktoplant/sponsor`];

export type GetWalkToPlantSponsorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWalkToPlantSponsor>>
>;
export type GetWalkToPlantSponsorQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetWalkToPlantSponsor = <
  TData = Awaited<ReturnType<typeof getWalkToPlantSponsor>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getWalkToPlantSponsor>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWalkToPlantSponsorQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWalkToPlantSponsor>>> = ({ signal }) =>
    getWalkToPlantSponsor(signal);

  const query = useQuery<Awaited<ReturnType<typeof getWalkToPlantSponsor>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * expectedAchClearanceDate
Calculate the expected clearance date for an ACH transfer.
The time param should be of the ISO-8601 format: "2006-01-02T15:04:05Z07:00"
 */
export const expectedAchClearanceDate = (expectedAchClearanceTime: ExpectedAchClearanceTime) => {
  return client<ExpectedAchClearanceDateResponseResponse>({
    url: `/expectedAchClearanceDate`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: expectedAchClearanceTime,
  });
};

export type ExpectedAchClearanceDateMutationResult = NonNullable<
  Awaited<ReturnType<typeof expectedAchClearanceDate>>
>;
export type ExpectedAchClearanceDateMutationBody = ExpectedAchClearanceTime;
export type ExpectedAchClearanceDateMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useExpectedAchClearanceDate = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expectedAchClearanceDate>>,
    TError,
    { data: ExpectedAchClearanceTime },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expectedAchClearanceDate>>,
    { data: ExpectedAchClearanceTime }
  > = (props) => {
    const { data } = props ?? {};

    return expectedAchClearanceDate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof expectedAchClearanceDate>>,
    TError,
    { data: ExpectedAchClearanceTime },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary # Gets or creates the Argyle user.
 */
export const getArgyleUser = (signal?: AbortSignal) => {
  return client<ArgyleUserResponseResponse>({ url: `/jwt/argyle/user`, method: 'get', signal });
};

export const getGetArgyleUserQueryKey = () => [`/jwt/argyle/user`];

export type GetArgyleUserQueryResult = NonNullable<Awaited<ReturnType<typeof getArgyleUser>>>;
export type GetArgyleUserQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetArgyleUser = <
  TData = Awaited<ReturnType<typeof getArgyleUser>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getArgyleUser>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetArgyleUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArgyleUser>>> = ({ signal }) =>
    getArgyleUser(signal);

  const query = useQuery<Awaited<ReturnType<typeof getArgyleUser>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * createPasswordUser
Create the user initially, the first step in onboarding. This endpoint is used for users authenticating with a username&password as part of the secure login flow.
 */
export const createPasswordUser = (createPasswordUserBody: CreatePasswordUserBody) => {
  return client<CreatePasswordUserOutputResponse>({
    url: `/jwt/onboarding/password_users`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createPasswordUserBody,
  });
};

export type CreatePasswordUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPasswordUser>>
>;
export type CreatePasswordUserMutationBody = CreatePasswordUserBody;
export type CreatePasswordUserMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCreatePasswordUser = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPasswordUser>>,
    TError,
    { data: CreatePasswordUserBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPasswordUser>>,
    { data: CreatePasswordUserBody }
  > = (props) => {
    const { data } = props ?? {};

    return createPasswordUser(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createPasswordUser>>,
    TError,
    { data: CreatePasswordUserBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * This should really be renamed to 'referral code' and allows a waitlist user to come off the waitlist
by entering a code. Originally we had the concept of a referral code and a golden ticket code but have
since unified this into a single referral code
 */
export const goldenTicketCode = (goldenTicketCodePayload: GoldenTicketCodePayload) => {
  return client<UserResponseResponse>({
    url: `/jwt/onboarding/user/goldenTicket`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: goldenTicketCodePayload,
  });
};

export type GoldenTicketCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof goldenTicketCode>>
>;
export type GoldenTicketCodeMutationBody = GoldenTicketCodePayload;
export type GoldenTicketCodeMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useGoldenTicketCode = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof goldenTicketCode>>,
    TError,
    { data: GoldenTicketCodePayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof goldenTicketCode>>,
    { data: GoldenTicketCodePayload }
  > = (props) => {
    const { data } = props ?? {};

    return goldenTicketCode(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof goldenTicketCode>>,
    TError,
    { data: GoldenTicketCodePayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Add home address details to the user, this is the third step in onboarding
Optionally add invitedBy and campaign here too
 */
export const homeAddress = (homeAddressPayload: HomeAddressPayload) => {
  return client<HomeAddressOutputResponse>({
    url: `/jwt/onboarding/user/homeAddress`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: homeAddressPayload,
  });
};

export type HomeAddressMutationResult = NonNullable<Awaited<ReturnType<typeof homeAddress>>>;
export type HomeAddressMutationBody = HomeAddressPayload;
export type HomeAddressMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useHomeAddress = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof homeAddress>>,
    TError,
    { data: HomeAddressPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof homeAddress>>,
    { data: HomeAddressPayload }
  > = (props) => {
    const { data } = props ?? {};

    return homeAddress(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof homeAddress>>,
    TError,
    { data: HomeAddressPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Create a KYC Token for identity verification
 */
export const createKycToken = (signal?: AbortSignal) => {
  return client<CreateKycTokenResponseResponse>({
    url: `/jwt/onboarding/user/kycToken`,
    method: 'get',
    signal,
  });
};

export const getCreateKycTokenQueryKey = () => [`/jwt/onboarding/user/kycToken`];

export type CreateKycTokenQueryResult = NonNullable<Awaited<ReturnType<typeof createKycToken>>>;
export type CreateKycTokenQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useCreateKycToken = <
  TData = Awaited<ReturnType<typeof createKycToken>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof createKycToken>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCreateKycTokenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof createKycToken>>> = ({ signal }) =>
    createKycToken(signal);

  const query = useQuery<Awaited<ReturnType<typeof createKycToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary User has passed all of our KYC steps, and now we can order and add their card
 */
export const orderCard = () => {
  return client<UserResponseResponse>({ url: `/jwt/onboarding/user/orderCard`, method: 'post' });
};

export type OrderCardMutationResult = NonNullable<Awaited<ReturnType<typeof orderCard>>>;

export type OrderCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useOrderCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof orderCard>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof orderCard>>, TVariables> = () => {
    return orderCard();
  };

  return useMutation<Awaited<ReturnType<typeof orderCard>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary Add all of the personal details to the user, this is the second step in onboarding
 */
export const personalDetails = (personalDetailsPayload: PersonalDetailsPayload) => {
  return client<PersonalDetailsOutputResponse>({
    url: `/jwt/onboarding/user/personalDetails`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: personalDetailsPayload,
  });
};

export type PersonalDetailsMutationResult = NonNullable<
  Awaited<ReturnType<typeof personalDetails>>
>;
export type PersonalDetailsMutationBody = PersonalDetailsPayload;
export type PersonalDetailsMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePersonalDetails = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof personalDetails>>,
    TError,
    { data: PersonalDetailsPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof personalDetails>>,
    { data: PersonalDetailsPayload }
  > = (props) => {
    const { data } = props ?? {};

    return personalDetails(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof personalDetails>>,
    TError,
    { data: PersonalDetailsPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Set the user's status to either dashboard or back to their product onboarding status
 */
export const setStatus = (setStatusRequest: SetStatusRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/onboarding/user/set_status`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: setStatusRequest,
  });
};

export type SetStatusMutationResult = NonNullable<Awaited<ReturnType<typeof setStatus>>>;
export type SetStatusMutationBody = SetStatusRequest;
export type SetStatusMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetStatus = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setStatus>>,
    TError,
    { data: SetStatusRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setStatus>>,
    { data: SetStatusRequest }
  > = (props) => {
    const { data } = props ?? {};

    return setStatus(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setStatus>>,
    TError,
    { data: SetStatusRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Set the user's status to plaid if they are in onboarding
 */
export const setStatusPlaid = () => {
  return client<EmptyResponseResponse>({
    url: `/jwt/onboarding/user/set_status_plaid`,
    method: 'patch',
  });
};

export type SetStatusPlaidMutationResult = NonNullable<Awaited<ReturnType<typeof setStatusPlaid>>>;

export type SetStatusPlaidMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetStatusPlaid = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setStatusPlaid>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setStatusPlaid>>,
    TVariables
  > = () => {
    return setStatusPlaid();
  };

  return useMutation<Awaited<ReturnType<typeof setStatusPlaid>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * uploadVerificationID
Upload the VerificationID received from au10tix id document submission returning the current verification status.
Use the GET method below to receive an updated status.
 */
export const uploadVerificationID = (uploadVerificationIDRequest: UploadVerificationIDRequest) => {
  return client<VerificationIDStatusResponseResponse>({
    url: `/jwt/onboarding/user/verificationIDStatus`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: uploadVerificationIDRequest,
  });
};

export type UploadVerificationIDMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadVerificationID>>
>;
export type UploadVerificationIDMutationBody = UploadVerificationIDRequest;
export type UploadVerificationIDMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useUploadVerificationID = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadVerificationID>>,
    TError,
    { data: UploadVerificationIDRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadVerificationID>>,
    { data: UploadVerificationIDRequest }
  > = (props) => {
    const { data } = props ?? {};

    return uploadVerificationID(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof uploadVerificationID>>,
    TError,
    { data: UploadVerificationIDRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary User is off waitlist, can submit SSN
 */
export const verifySSN = (verifySSNRequest: VerifySSNRequest) => {
  return client<UserResponseResponse>({
    url: `/jwt/onboarding/user/verifySSN`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: verifySSNRequest,
  });
};

export type VerifySSNMutationResult = NonNullable<Awaited<ReturnType<typeof verifySSN>>>;
export type VerifySSNMutationBody = VerifySSNRequest;
export type VerifySSNMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useVerifySSN = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifySSN>>,
    TError,
    { data: VerifySSNRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifySSN>>,
    { data: VerifySSNRequest }
  > = (props) => {
    const { data } = props ?? {};

    return verifySSN(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof verifySSN>>,
    TError,
    { data: VerifySSNRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * This is step of onboarding occurs if a user is in waitlist_questionnaire state
 */
export const waitlistQuestionnaire = (waitlistQuestionnaire: WaitlistQuestionnaire) => {
  return client<WaitlistQuestionnaireOutputResponse>({
    url: `/jwt/onboarding/user/waitlistQuestionnaire`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: waitlistQuestionnaire,
  });
};

export type WaitlistQuestionnaireMutationResult = NonNullable<
  Awaited<ReturnType<typeof waitlistQuestionnaire>>
>;
export type WaitlistQuestionnaireMutationBody = WaitlistQuestionnaire;
export type WaitlistQuestionnaireMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useWaitlistQuestionnaire = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof waitlistQuestionnaire>>,
    TError,
    { data: WaitlistQuestionnaire },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof waitlistQuestionnaire>>,
    { data: WaitlistQuestionnaire }
  > = (props) => {
    const { data } = props ?? {};

    return waitlistQuestionnaire(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof waitlistQuestionnaire>>,
    TError,
    { data: WaitlistQuestionnaire },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get a single user by id
 */
export const getSingleUser = (signal?: AbortSignal) => {
  return client<UserResponseResponse>({ url: `/jwt/user`, method: 'get', signal });
};

export const getGetSingleUserQueryKey = () => [`/jwt/user`];

export type GetSingleUserQueryResult = NonNullable<Awaited<ReturnType<typeof getSingleUser>>>;
export type GetSingleUserQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetSingleUser = <
  TData = Awaited<ReturnType<typeof getSingleUser>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSingleUser>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSingleUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSingleUser>>> = ({ signal }) =>
    getSingleUser(signal);

  const query = useQuery<Awaited<ReturnType<typeof getSingleUser>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Request body contains the user object. Only those attibutes that
exist in the body will be updated
 * @summary updateUser
Update a user with the given userid
 */
export const updateUser = (updateUserPayload: UpdateUserPayload) => {
  return client<UserResponseResponse>({
    url: `/jwt/user`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateUserPayload,
  });
};

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>;
export type UpdateUserMutationBody = UpdateUserPayload;
export type UpdateUserMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useUpdateUser = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UpdateUserPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { data: UpdateUserPayload }
  > = (props) => {
    const { data } = props ?? {};

    return updateUser(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UpdateUserPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * This API returns a given user's bank accounts that have been connected
to their account and registered with the bank.
 * @summary Get user bank accounts
 */
export const getUserBankAccounts = (signal?: AbortSignal) => {
  return client<GetUserBankAccountsResponseResponse>({
    url: `/jwt/user/bankAccounts`,
    method: 'get',
    signal,
  });
};

export const getGetUserBankAccountsQueryKey = () => [`/jwt/user/bankAccounts`];

export type GetUserBankAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserBankAccounts>>
>;
export type GetUserBankAccountsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserBankAccounts = <
  TData = Awaited<ReturnType<typeof getUserBankAccounts>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserBankAccounts>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserBankAccountsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserBankAccounts>>> = ({ signal }) =>
    getUserBankAccounts(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserBankAccounts>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * # This API adds and registers a linked bank account, saving it in the database
 * @summary addNewBankAccountWithoutCard
Add a new bank account without linking to an i2c card
 */
export const addNewBankAccountWithoutCard = (
  addNewBankAccountToCardRequestBody: AddNewBankAccountToCardRequestBody,
) => {
  return client<AddNewBankAccountToCardResponseResponse>({
    url: `/jwt/user/bankAccounts`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addNewBankAccountToCardRequestBody,
  });
};

export type AddNewBankAccountWithoutCardMutationResult = NonNullable<
  Awaited<ReturnType<typeof addNewBankAccountWithoutCard>>
>;
export type AddNewBankAccountWithoutCardMutationBody = AddNewBankAccountToCardRequestBody;
export type AddNewBankAccountWithoutCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useAddNewBankAccountWithoutCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addNewBankAccountWithoutCard>>,
    TError,
    { data: AddNewBankAccountToCardRequestBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addNewBankAccountWithoutCard>>,
    { data: AddNewBankAccountToCardRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return addNewBankAccountWithoutCard(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof addNewBankAccountWithoutCard>>,
    TError,
    { data: AddNewBankAccountToCardRequestBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * # This API removes a bank by marking the account as deleted in our system
 * @summary removeBank
Remove a bank accountd
 */
export const removeBank = (bankAccountId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/bankAccounts/${bankAccountId}`,
    method: 'delete',
  });
};

export type RemoveBankMutationResult = NonNullable<Awaited<ReturnType<typeof removeBank>>>;

export type RemoveBankMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useRemoveBank = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeBank>>,
    TError,
    { bankAccountId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeBank>>,
    { bankAccountId: string }
  > = (props) => {
    const { bankAccountId } = props ?? {};

    return removeBank(bankAccountId);
  };

  return useMutation<
    Awaited<ReturnType<typeof removeBank>>,
    TError,
    { bankAccountId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get past & present bonus offers applicable to the user
 */
export const getBonusOffers = (signal?: AbortSignal) => {
  return client<BonusOffersResponseResponse>({
    url: `/jwt/user/bonus_offers`,
    method: 'get',
    signal,
  });
};

export const getGetBonusOffersQueryKey = () => [`/jwt/user/bonus_offers`];

export type GetBonusOffersQueryResult = NonNullable<Awaited<ReturnType<typeof getBonusOffers>>>;
export type GetBonusOffersQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetBonusOffers = <
  TData = Awaited<ReturnType<typeof getBonusOffers>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getBonusOffers>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonusOffersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBonusOffers>>> = ({ signal }) =>
    getBonusOffers(signal);

  const query = useQuery<Awaited<ReturnType<typeof getBonusOffers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Update a bonus offer
 */
export const updateBonusOffer = (
  userBonusOfferId: string,
  updateBonusOfferPayload: UpdateBonusOfferPayload,
) => {
  return client<BonusOffersResponseResponse>({
    url: `/jwt/user/bonus_offers/${userBonusOfferId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateBonusOfferPayload,
  });
};

export type UpdateBonusOfferMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBonusOffer>>
>;
export type UpdateBonusOfferMutationBody = UpdateBonusOfferPayload;
export type UpdateBonusOfferMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useUpdateBonusOffer = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBonusOffer>>,
    TError,
    { userBonusOfferId: string; data: UpdateBonusOfferPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBonusOffer>>,
    { userBonusOfferId: string; data: UpdateBonusOfferPayload }
  > = (props) => {
    const { userBonusOfferId, data } = props ?? {};

    return updateBonusOffer(userBonusOfferId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateBonusOffer>>,
    TError,
    { userBonusOfferId: string; data: UpdateBonusOfferPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get cards of a user
 */
export const getCards = (signal?: AbortSignal) => {
  return client<CardsResponseResponse>({ url: `/jwt/user/cards`, method: 'get', signal });
};

export const getGetCardsQueryKey = () => [`/jwt/user/cards`];

export type GetCardsQueryResult = NonNullable<Awaited<ReturnType<typeof getCards>>>;
export type GetCardsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCards = <
  TData = Awaited<ReturnType<typeof getCards>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCards>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCards>>> = ({ signal }) =>
    getCards(signal);

  const query = useQuery<Awaited<ReturnType<typeof getCards>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This API accepts from and to date query parameters, both of which
are in yyyy-mm-dd format. If fromDate is omitted, now - 3 months is
assumed. If toDate is omitted, now is assumed. Without specifying the
limit parameter a maxium of 100 transactions will be returned.

Returns all a users phyiscial & virtual card transactions but excludes other cards like pots
 * @summary Get transaction history
 */
export const getPhysicalAndVirtualTransactions = (
  params?: GetPhysicalAndVirtualTransactionsParams,
  signal?: AbortSignal,
) => {
  return client<TransactionsResponseResponse>({
    url: `/jwt/user/cards/transactions`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetPhysicalAndVirtualTransactionsQueryKey = (
  params?: GetPhysicalAndVirtualTransactionsParams,
) => [`/jwt/user/cards/transactions`, ...(params ? [params] : [])];

export type GetPhysicalAndVirtualTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPhysicalAndVirtualTransactions>>
>;
export type GetPhysicalAndVirtualTransactionsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetPhysicalAndVirtualTransactions = <
  TData = Awaited<ReturnType<typeof getPhysicalAndVirtualTransactions>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetPhysicalAndVirtualTransactionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPhysicalAndVirtualTransactions>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPhysicalAndVirtualTransactionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPhysicalAndVirtualTransactions>>> = ({
    signal,
  }) => getPhysicalAndVirtualTransactions(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPhysicalAndVirtualTransactions>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Activate a card
 */
export const activateCard = (cardId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/activate`,
    method: 'post',
  });
};

export type ActivateCardMutationResult = NonNullable<Awaited<ReturnType<typeof activateCard>>>;

export type ActivateCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useActivateCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activateCard>>,
    TError,
    { cardId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activateCard>>,
    { cardId: string }
  > = (props) => {
    const { cardId } = props ?? {};

    return activateCard(cardId);
  };

  return useMutation<
    Awaited<ReturnType<typeof activateCard>>,
    TError,
    { cardId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Add funds to a card
 */
export const addFundsToCard = (cardId: string, addFundsToCardRequest: AddFundsToCardRequest) => {
  return client<AddFundsToCardResponseResponse>({
    url: `/jwt/user/cards/${cardId}/addFunds`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addFundsToCardRequest,
  });
};

export type AddFundsToCardMutationResult = NonNullable<Awaited<ReturnType<typeof addFundsToCard>>>;
export type AddFundsToCardMutationBody = AddFundsToCardRequest;
export type AddFundsToCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useAddFundsToCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addFundsToCard>>,
    TError,
    { cardId: string; data: AddFundsToCardRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addFundsToCard>>,
    { cardId: string; data: AddFundsToCardRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return addFundsToCard(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof addFundsToCard>>,
    TError,
    { cardId: string; data: AddFundsToCardRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Returns whether to prompt when adding funds
 */
export const addFundsToCardPrompt = (
  cardId: string,
  addFundsToCardPromptRequest: AddFundsToCardPromptRequest,
) => {
  return client<AddFundsToCardPromptResponseResponse>({
    url: `/jwt/user/cards/${cardId}/addFunds/prompt`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addFundsToCardPromptRequest,
  });
};

export type AddFundsToCardPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof addFundsToCardPrompt>>
>;
export type AddFundsToCardPromptMutationBody = AddFundsToCardPromptRequest;
export type AddFundsToCardPromptMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useAddFundsToCardPrompt = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addFundsToCardPrompt>>,
    TError,
    { cardId: string; data: AddFundsToCardPromptRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addFundsToCardPrompt>>,
    { cardId: string; data: AddFundsToCardPromptRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return addFundsToCardPrompt(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof addFundsToCardPrompt>>,
    TError,
    { cardId: string; data: AddFundsToCardPromptRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get authorization data for the card
 */
export const getCardAuthData = (cardId: string, signal?: AbortSignal) => {
  return client<GetCardAuthDataResponseResponse>({
    url: `/jwt/user/cards/${cardId}/auth`,
    method: 'get',
    signal,
  });
};

export const getGetCardAuthDataQueryKey = (cardId: string) => [`/jwt/user/cards/${cardId}/auth`];

export type GetCardAuthDataQueryResult = NonNullable<Awaited<ReturnType<typeof getCardAuthData>>>;
export type GetCardAuthDataQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCardAuthData = <
  TData = Awaited<ReturnType<typeof getCardAuthData>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCardAuthData>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardAuthDataQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardAuthData>>> = ({ signal }) =>
    getCardAuthData(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardAuthData>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!cardId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get balance of a card
 */
export const getBalance = (cardId: string, signal?: AbortSignal) => {
  return client<GetBalanceResponseResponse>({
    url: `/jwt/user/cards/${cardId}/balance`,
    method: 'get',
    signal,
  });
};

export const getGetBalanceQueryKey = (cardId: string) => [`/jwt/user/cards/${cardId}/balance`];

export type GetBalanceQueryResult = NonNullable<Awaited<ReturnType<typeof getBalance>>>;
export type GetBalanceQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetBalance = <
  TData = Awaited<ReturnType<typeof getBalance>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getBalance>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBalanceQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBalance>>> = ({ signal }) =>
    getBalance(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBalance>>, TError, TData>(queryKey, queryFn, {
    enabled: !!cardId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This API adds and registers a linked bank account to a card, saving it in the database
with an AccountSrNo that can be used in future for bank to card transfers
 * @summary addNewBankAccountToCard
Add a new bank account to card
 */
export const addNewBankAccountToCard = (
  cardId: string,
  addNewBankAccountToCardRequestBody: AddNewBankAccountToCardRequestBody,
) => {
  return client<AddNewBankAccountToCardResponseResponse>({
    url: `/jwt/user/cards/${cardId}/bankAccount`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addNewBankAccountToCardRequestBody,
  });
};

export type AddNewBankAccountToCardMutationResult = NonNullable<
  Awaited<ReturnType<typeof addNewBankAccountToCard>>
>;
export type AddNewBankAccountToCardMutationBody = AddNewBankAccountToCardRequestBody;
export type AddNewBankAccountToCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useAddNewBankAccountToCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addNewBankAccountToCard>>,
    TError,
    { cardId: string; data: AddNewBankAccountToCardRequestBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addNewBankAccountToCard>>,
    { cardId: string; data: AddNewBankAccountToCardRequestBody }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return addNewBankAccountToCard(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof addNewBankAccountToCard>>,
    TError,
    { cardId: string; data: AddNewBankAccountToCardRequestBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * # This API removes a bank account from Plaid, I2C and marks the account as deleted in our system
 * @deprecated
 * @summary removeBankFromCard
Remove a bank account from a card
 */
export const removeBankFromCard = (cardId: string, bankAccountId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/bankAccount/${bankAccountId}`,
    method: 'delete',
  });
};

export type RemoveBankFromCardMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeBankFromCard>>
>;

export type RemoveBankFromCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useRemoveBankFromCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeBankFromCard>>,
    TError,
    { cardId: string; bankAccountId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeBankFromCard>>,
    { cardId: string; bankAccountId: string }
  > = (props) => {
    const { cardId, bankAccountId } = props ?? {};

    return removeBankFromCard(cardId, bankAccountId);
  };

  return useMutation<
    Awaited<ReturnType<typeof removeBankFromCard>>,
    TError,
    { cardId: string; bankAccountId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get bank details for the card
 */
export const getCardBankDetails = (cardId: string, signal?: AbortSignal) => {
  return client<GetCardBankDetailsResponseResponse>({
    url: `/jwt/user/cards/${cardId}/bankDetails`,
    method: 'get',
    signal,
  });
};

export const getGetCardBankDetailsQueryKey = (cardId: string) => [
  `/jwt/user/cards/${cardId}/bankDetails`,
];

export type GetCardBankDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCardBankDetails>>
>;
export type GetCardBankDetailsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCardBankDetails = <
  TData = Awaited<ReturnType<typeof getCardBankDetails>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCardBankDetails>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardBankDetailsQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardBankDetails>>> = ({ signal }) =>
    getCardBankDetails(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardBankDetails>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!cardId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Cancels treepay pre auth
 */
export const cancelPreAuth = (cardId: string, cancelPreAuthRequest: CancelPreAuthRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/cancelPreAuth`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: cancelPreAuthRequest,
  });
};

export type CancelPreAuthMutationResult = NonNullable<Awaited<ReturnType<typeof cancelPreAuth>>>;
export type CancelPreAuthMutationBody = CancelPreAuthRequest;
export type CancelPreAuthMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCancelPreAuth = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelPreAuth>>,
    TError,
    { cardId: string; data: CancelPreAuthRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelPreAuth>>,
    { cardId: string; data: CancelPreAuthRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return cancelPreAuth(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof cancelPreAuth>>,
    TError,
    { cardId: string; data: CancelPreAuthRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Creates a charity donation for the user's chosen charity.
 */
export const setCharityDonation = (
  cardId: string,
  setCharityDonationPayload: SetCharityDonationPayload,
) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/charity_donation`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: setCharityDonationPayload,
  });
};

export type SetCharityDonationMutationResult = NonNullable<
  Awaited<ReturnType<typeof setCharityDonation>>
>;
export type SetCharityDonationMutationBody = SetCharityDonationPayload;
export type SetCharityDonationMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetCharityDonation = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setCharityDonation>>,
    TError,
    { cardId: string; data: SetCharityDonationPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setCharityDonation>>,
    { cardId: string; data: SetCharityDonationPayload }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return setCharityDonation(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setCharityDonation>>,
    TError,
    { cardId: string; data: SetCharityDonationPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the estimated delivery status of a card
 */
export const getCardDeliveryStatus = (cardId: string, signal?: AbortSignal) => {
  return client<GetCardDeliveryStatusResponseResponse>({
    url: `/jwt/user/cards/${cardId}/deliveryStatus`,
    method: 'get',
    signal,
  });
};

export const getGetCardDeliveryStatusQueryKey = (cardId: string) => [
  `/jwt/user/cards/${cardId}/deliveryStatus`,
];

export type GetCardDeliveryStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCardDeliveryStatus>>
>;
export type GetCardDeliveryStatusQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCardDeliveryStatus = <
  TData = Awaited<ReturnType<typeof getCardDeliveryStatus>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCardDeliveryStatus>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardDeliveryStatusQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardDeliveryStatus>>> = ({ signal }) =>
    getCardDeliveryStatus(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardDeliveryStatus>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!cardId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This API adds and registers a linked bank account to a card, saving it
with an AccountSrNo that can be used in future for bank to card transfers
 * @summary finalizeManualVerificationBankAccount
Add a new bank account that has already been verified through plaid manual verification to card
 */
export const finalizeManualVerificationBankAccount = (cardId: string) => {
  return client<AddNewBankAccountToCardResponseResponse>({
    url: `/jwt/user/cards/${cardId}/finalizeManualVerificationBankAccount`,
    method: 'post',
  });
};

export type FinalizeManualVerificationBankAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof finalizeManualVerificationBankAccount>>
>;

export type FinalizeManualVerificationBankAccountMutationError =
  ErrorType<SycamoreErrorResponseResponse>;

export const useFinalizeManualVerificationBankAccount = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof finalizeManualVerificationBankAccount>>,
    TError,
    { cardId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof finalizeManualVerificationBankAccount>>,
    { cardId: string }
  > = (props) => {
    const { cardId } = props ?? {};

    return finalizeManualVerificationBankAccount(cardId);
  };

  return useMutation<
    Awaited<ReturnType<typeof finalizeManualVerificationBankAccount>>,
    TError,
    { cardId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Freeze the card
 */
export const freezeCard = (cardId: string) => {
  return client<EmptyResponseResponse>({ url: `/jwt/user/cards/${cardId}/freeze`, method: 'post' });
};

export type FreezeCardMutationResult = NonNullable<Awaited<ReturnType<typeof freezeCard>>>;

export type FreezeCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useFreezeCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof freezeCard>>,
    TError,
    { cardId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof freezeCard>>, { cardId: string }> = (
    props,
  ) => {
    const { cardId } = props ?? {};

    return freezeCard(cardId);
  };

  return useMutation<Awaited<ReturnType<typeof freezeCard>>, TError, { cardId: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary Sets up a new funding card
 */
export const initializeFirstTimeCardPayment = (
  cardId: string,
  initializeFirstTimeCardPaymentRequest: InitializeFirstTimeCardPaymentRequest,
) => {
  return client<InitializeFirstTimeCardPaymentResponseResponse>({
    url: `/jwt/user/cards/${cardId}/initializeFirstTimeCardPayment`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: initializeFirstTimeCardPaymentRequest,
  });
};

export type InitializeFirstTimeCardPaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof initializeFirstTimeCardPayment>>
>;
export type InitializeFirstTimeCardPaymentMutationBody = InitializeFirstTimeCardPaymentRequest;
export type InitializeFirstTimeCardPaymentMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useInitializeFirstTimeCardPayment = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initializeFirstTimeCardPayment>>,
    TError,
    { cardId: string; data: InitializeFirstTimeCardPaymentRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof initializeFirstTimeCardPayment>>,
    { cardId: string; data: InitializeFirstTimeCardPaymentRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return initializeFirstTimeCardPayment(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof initializeFirstTimeCardPayment>>,
    TError,
    { cardId: string; data: InitializeFirstTimeCardPaymentRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get PIN for the card
 */
export const getCardPIN = (cardId: string, signal?: AbortSignal) => {
  return client<GetCardPINResponseResponse>({
    url: `/jwt/user/cards/${cardId}/pin`,
    method: 'get',
    signal,
  });
};

export const getGetCardPINQueryKey = (cardId: string) => [`/jwt/user/cards/${cardId}/pin`];

export type GetCardPINQueryResult = NonNullable<Awaited<ReturnType<typeof getCardPIN>>>;
export type GetCardPINQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCardPIN = <
  TData = Awaited<ReturnType<typeof getCardPIN>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCardPIN>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardPINQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardPIN>>> = ({ signal }) =>
    getCardPIN(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardPIN>>, TError, TData>(queryKey, queryFn, {
    enabled: !!cardId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Set the card pin
 */
export const setCardPin = (cardId: string, setCardPinRequest: SetCardPinRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/pin`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: setCardPinRequest,
  });
};

export type SetCardPinMutationResult = NonNullable<Awaited<ReturnType<typeof setCardPin>>>;
export type SetCardPinMutationBody = SetCardPinRequest;
export type SetCardPinMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetCardPin = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setCardPin>>,
    TError,
    { cardId: string; data: SetCardPinRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setCardPin>>,
    { cardId: string; data: SetCardPinRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return setCardPin(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setCardPin>>,
    TError,
    { cardId: string; data: SetCardPinRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Send funds from the user's card to the specified treecard
 */
export const sendFunds = (cardId: string, sendFundsRequest: SendFundsRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/sendFunds`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: sendFundsRequest,
  });
};

export type SendFundsMutationResult = NonNullable<Awaited<ReturnType<typeof sendFunds>>>;
export type SendFundsMutationBody = SendFundsRequest;
export type SendFundsMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSendFunds = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendFunds>>,
    TError,
    { cardId: string; data: SendFundsRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendFunds>>,
    { cardId: string; data: SendFundsRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return sendFunds(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof sendFunds>>,
    TError,
    { cardId: string; data: SendFundsRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get status for the card
 */
export const getCardStatus = (cardId: string, signal?: AbortSignal) => {
  return client<GetCardStatusResponseResponse>({
    url: `/jwt/user/cards/${cardId}/status`,
    method: 'get',
    signal,
  });
};

export const getGetCardStatusQueryKey = (cardId: string) => [`/jwt/user/cards/${cardId}/status`];

export type GetCardStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getCardStatus>>>;
export type GetCardStatusQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCardStatus = <
  TData = Awaited<ReturnType<typeof getCardStatus>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCardStatus>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardStatusQueryKey(cardId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardStatus>>> = ({ signal }) =>
    getCardStatus(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardStatus>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!cardId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This API accepts from and to date query parameters, both of which
are in yyyy-mm-dd format. If fromDate is omitted, now - 3 months is
assumed. If toDate is omitted, now is assumed. Without specifying the
limit parameter a maxium of 100 transactions will be returned
 * @summary Get transaction history
 */
export const getTransactions = (
  cardId: string,
  params?: GetTransactionsParams,
  signal?: AbortSignal,
) => {
  return client<TransactionsResponseResponse>({
    url: `/jwt/user/cards/${cardId}/transactions`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetTransactionsQueryKey = (cardId: string, params?: GetTransactionsParams) => [
  `/jwt/user/cards/${cardId}/transactions`,
  ...(params ? [params] : []),
];

export type GetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactions>>>;
export type GetTransactionsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTransactions = <
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  cardId: string,
  params?: GetTransactionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsQueryKey(cardId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactions>>> = ({ signal }) =>
    getTransactions(cardId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransactions>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!cardId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Unfreeze a frozen card
 */
export const unfreezeCard = (cardId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/unfreeze`,
    method: 'post',
  });
};

export type UnfreezeCardMutationResult = NonNullable<Awaited<ReturnType<typeof unfreezeCard>>>;

export type UnfreezeCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useUnfreezeCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unfreezeCard>>,
    TError,
    { cardId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unfreezeCard>>,
    { cardId: string }
  > = (props) => {
    const { cardId } = props ?? {};

    return unfreezeCard(cardId);
  };

  return useMutation<
    Awaited<ReturnType<typeof unfreezeCard>>,
    TError,
    { cardId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Generates push provisioning payload for a digital wallet provider like Apple Pay
 */
export const generateWalletProviderData = (
  cardId: string,
  generateWalletProviderDataPayload: GenerateWalletProviderDataPayload,
) => {
  return client<GenerateWalletProviderDataResponseResponse>({
    url: `/jwt/user/cards/${cardId}/walletProviderData`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: generateWalletProviderDataPayload,
  });
};

export type GenerateWalletProviderDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateWalletProviderData>>
>;
export type GenerateWalletProviderDataMutationBody = GenerateWalletProviderDataPayload;
export type GenerateWalletProviderDataMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useGenerateWalletProviderData = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateWalletProviderData>>,
    TError,
    { cardId: string; data: GenerateWalletProviderDataPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateWalletProviderData>>,
    { cardId: string; data: GenerateWalletProviderDataPayload }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return generateWalletProviderData(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof generateWalletProviderData>>,
    TError,
    { cardId: string; data: GenerateWalletProviderDataPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Withdraw funds from the user's card to the specified bank account
 */
export const withdrawFunds = (cardId: string, withdrawFundsRequest: WithdrawFundsRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/cards/${cardId}/withdrawFunds`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: withdrawFundsRequest,
  });
};

export type WithdrawFundsMutationResult = NonNullable<Awaited<ReturnType<typeof withdrawFunds>>>;
export type WithdrawFundsMutationBody = WithdrawFundsRequest;
export type WithdrawFundsMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useWithdrawFunds = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawFunds>>,
    TError,
    { cardId: string; data: WithdrawFundsRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawFunds>>,
    { cardId: string; data: WithdrawFundsRequest }
  > = (props) => {
    const { cardId, data } = props ?? {};

    return withdrawFunds(cardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof withdrawFunds>>,
    TError,
    { cardId: string; data: WithdrawFundsRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * # Returns the userID if found, otherwise null
 * @summary Checks if a user exists for any of the provided email adresses or phone numbers
 */
export const checkContact = (checkContactPayload: CheckContactPayload) => {
  return client<CheckContactResponseResponse>({
    url: `/jwt/user/checkContact`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: checkContactPayload,
  });
};

export type CheckContactMutationResult = NonNullable<Awaited<ReturnType<typeof checkContact>>>;
export type CheckContactMutationBody = CheckContactPayload;
export type CheckContactMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCheckContact = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkContact>>,
    TError,
    { data: CheckContactPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkContact>>,
    { data: CheckContactPayload }
  > = (props) => {
    const { data } = props ?? {};

    return checkContact(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof checkContact>>,
    TError,
    { data: CheckContactPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the user's config they can configure themselves
 */
export const getUserConfig = (signal?: AbortSignal) => {
  return client<UserConfigResponseResponse>({ url: `/jwt/user/config`, method: 'get', signal });
};

export const getGetUserConfigQueryKey = () => [`/jwt/user/config`];

export type GetUserConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getUserConfig>>>;
export type GetUserConfigQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserConfig = <
  TData = Awaited<ReturnType<typeof getUserConfig>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserConfig>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserConfigQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserConfig>>> = ({ signal }) =>
    getUserConfig(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserConfig>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Set the user configuration for a given name
 */
export const setUserConfig = (setUserConfigPayload: SetUserConfigPayload) => {
  return client<UserConfigResponseResponse>({
    url: `/jwt/user/config`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: setUserConfigPayload,
  });
};

export type SetUserConfigMutationResult = NonNullable<Awaited<ReturnType<typeof setUserConfig>>>;
export type SetUserConfigMutationBody = SetUserConfigPayload;
export type SetUserConfigMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetUserConfig = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setUserConfig>>,
    TError,
    { data: SetUserConfigPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setUserConfig>>,
    { data: SetUserConfigPayload }
  > = (props) => {
    const { data } = props ?? {};

    return setUserConfig(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setUserConfig>>,
    TError,
    { data: SetUserConfigPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary # Get discover notifications.
 */
export const getDiscoverNotifications = (signal?: AbortSignal) => {
  return client<DiscoverNotificationsResponseResponse>({
    url: `/jwt/user/discoverNotifications`,
    method: 'get',
    signal,
  });
};

export const getGetDiscoverNotificationsQueryKey = () => [`/jwt/user/discoverNotifications`];

export type GetDiscoverNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDiscoverNotifications>>
>;
export type GetDiscoverNotificationsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetDiscoverNotifications = <
  TData = Awaited<ReturnType<typeof getDiscoverNotifications>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDiscoverNotifications>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscoverNotificationsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiscoverNotifications>>> = ({
    signal,
  }) => getDiscoverNotifications(signal);

  const query = useQuery<Awaited<ReturnType<typeof getDiscoverNotifications>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary # Updates all discover notifications.
 */
export const patchAllDiscoverNotification = () => {
  return client<EmptyResponseResponse>({ url: `/jwt/user/discoverNotifications`, method: 'patch' });
};

export type PatchAllDiscoverNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAllDiscoverNotification>>
>;

export type PatchAllDiscoverNotificationMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePatchAllDiscoverNotification = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAllDiscoverNotification>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAllDiscoverNotification>>,
    TVariables
  > = () => {
    return patchAllDiscoverNotification();
  };

  return useMutation<
    Awaited<ReturnType<typeof patchAllDiscoverNotification>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary # Update a discover notification.
 */
export const patchDiscoverNotification = (notificationID: string) => {
  return client<DiscoverNotificationResponseResponse>({
    url: `/jwt/user/discoverNotifications/${notificationID}`,
    method: 'patch',
  });
};

export type PatchDiscoverNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchDiscoverNotification>>
>;

export type PatchDiscoverNotificationMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePatchDiscoverNotification = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchDiscoverNotification>>,
    TError,
    { notificationID: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchDiscoverNotification>>,
    { notificationID: string }
  > = (props) => {
    const { notificationID } = props ?? {};

    return patchDiscoverNotification(notificationID);
  };

  return useMutation<
    Awaited<ReturnType<typeof patchDiscoverNotification>>,
    TError,
    { notificationID: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get top 5 updates for my trees / discover. Will Return one my tree update if available and upto 5 community updates
 */
export const getDiscoverTopUpdates = (signal?: AbortSignal) => {
  return client<DiscoverTopUpdatesResponseResponse>({
    url: `/jwt/user/discover_top_updates`,
    method: 'get',
    signal,
  });
};

export const getGetDiscoverTopUpdatesQueryKey = () => [`/jwt/user/discover_top_updates`];

export type GetDiscoverTopUpdatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDiscoverTopUpdates>>
>;
export type GetDiscoverTopUpdatesQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetDiscoverTopUpdates = <
  TData = Awaited<ReturnType<typeof getDiscoverTopUpdates>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDiscoverTopUpdates>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscoverTopUpdatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiscoverTopUpdates>>> = ({ signal }) =>
    getDiscoverTopUpdates(signal);

  const query = useQuery<Awaited<ReturnType<typeof getDiscoverTopUpdates>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get available offers to earn points along with previous offers a user has claimed
 */
export const getUserEarnPointOffers = (signal?: AbortSignal) => {
  return client<GetEarnPointOffersResponseResponse>({
    url: `/jwt/user/earnPointOffers`,
    method: 'get',
    signal,
  });
};

export const getGetUserEarnPointOffersQueryKey = () => [`/jwt/user/earnPointOffers`];

export type GetUserEarnPointOffersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserEarnPointOffers>>
>;
export type GetUserEarnPointOffersQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserEarnPointOffers = <
  TData = Awaited<ReturnType<typeof getUserEarnPointOffers>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserEarnPointOffers>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserEarnPointOffersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEarnPointOffers>>> = ({ signal }) =>
    getUserEarnPointOffers(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserEarnPointOffers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * createEvent
Create a new event in the users event log.
 */
export const createEvent = (eventPayload: EventPayload) => {
  return client<CreateEventResponseResponse>({
    url: `/jwt/user/events`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: eventPayload,
  });
};

export type CreateEventMutationResult = NonNullable<Awaited<ReturnType<typeof createEvent>>>;
export type CreateEventMutationBody = EventPayload;
export type CreateEventMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCreateEvent = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEvent>>,
    TError,
    { data: EventPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createEvent>>,
    { data: EventPayload }
  > = (props) => {
    const { data } = props ?? {};

    return createEvent(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createEvent>>,
    TError,
    { data: EventPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the user's defined feature flags to check feature availability
 */
export const getUserFeatureFlags = (signal?: AbortSignal) => {
  return client<UserFeatureFlagsResponseResponse>({
    url: `/jwt/user/feature_flags`,
    method: 'get',
    signal,
  });
};

export const getGetUserFeatureFlagsQueryKey = () => [`/jwt/user/feature_flags`];

export type GetUserFeatureFlagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFeatureFlags>>
>;
export type GetUserFeatureFlagsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserFeatureFlags = <
  TData = Awaited<ReturnType<typeof getUserFeatureFlags>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFeatureFlags>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFeatureFlagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFeatureFlags>>> = ({ signal }) =>
    getUserFeatureFlags(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserFeatureFlags>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's funding cards
 */
export const getUserFundingCards = (signal?: AbortSignal) => {
  return client<UserFundingCardsResponseResponse>({
    url: `/jwt/user/fundingCards`,
    method: 'get',
    signal,
  });
};

export const getGetUserFundingCardsQueryKey = () => [`/jwt/user/fundingCards`];

export type GetUserFundingCardsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFundingCards>>
>;
export type GetUserFundingCardsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserFundingCards = <
  TData = Awaited<ReturnType<typeof getUserFundingCards>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserFundingCards>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFundingCardsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFundingCards>>> = ({ signal }) =>
    getUserFundingCards(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserFundingCards>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Remove a funding card
 */
export const removeUserFundingCard = (fundingCardId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/fundingCards/${fundingCardId}`,
    method: 'delete',
  });
};

export type RemoveUserFundingCardMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeUserFundingCard>>
>;

export type RemoveUserFundingCardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useRemoveUserFundingCard = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFundingCard>>,
    TError,
    { fundingCardId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeUserFundingCard>>,
    { fundingCardId: string }
  > = (props) => {
    const { fundingCardId } = props ?? {};

    return removeUserFundingCard(fundingCardId);
  };

  return useMutation<
    Awaited<ReturnType<typeof removeUserFundingCard>>,
    TError,
    { fundingCardId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the user's gift reward transaction history
 */
export const getGiftRewardTransactions = (signal?: AbortSignal) => {
  return client<GiftRewardTransactionsResponseResponse>({
    url: `/jwt/user/giftedRewards`,
    method: 'get',
    signal,
  });
};

export const getGetGiftRewardTransactionsQueryKey = () => [`/jwt/user/giftedRewards`];

export type GetGiftRewardTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGiftRewardTransactions>>
>;
export type GetGiftRewardTransactionsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetGiftRewardTransactions = <
  TData = Awaited<ReturnType<typeof getGiftRewardTransactions>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getGiftRewardTransactions>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGiftRewardTransactionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGiftRewardTransactions>>> = ({
    signal,
  }) => getGiftRewardTransactions(signal);

  const query = useQuery<Awaited<ReturnType<typeof getGiftRewardTransactions>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Claims the specified gift transaction
 */
export const claimGiftReward = (claimGiftRewardRequest: ClaimGiftRewardRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/giftedRewards/claim`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: claimGiftRewardRequest,
  });
};

export type ClaimGiftRewardMutationResult = NonNullable<
  Awaited<ReturnType<typeof claimGiftReward>>
>;
export type ClaimGiftRewardMutationBody = ClaimGiftRewardRequest;
export type ClaimGiftRewardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useClaimGiftReward = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimGiftReward>>,
    TError,
    { data: ClaimGiftRewardRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof claimGiftReward>>,
    { data: ClaimGiftRewardRequest }
  > = (props) => {
    const { data } = props ?? {};

    return claimGiftReward(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof claimGiftReward>>,
    TError,
    { data: ClaimGiftRewardRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * getImpactStats
Get impact stats for a user, which includes trees planted, kg of CO2 sequestered,
acres protected and species housed
 */
export const getImpactStats = (signal?: AbortSignal) => {
  return client<ImpactStatsResponseResponse>({
    url: `/jwt/user/impactStats`,
    method: 'get',
    signal,
  });
};

export const getGetImpactStatsQueryKey = () => [`/jwt/user/impactStats`];

export type GetImpactStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getImpactStats>>>;
export type GetImpactStatsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetImpactStats = <
  TData = Awaited<ReturnType<typeof getImpactStats>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getImpactStats>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetImpactStatsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImpactStats>>> = ({ signal }) =>
    getImpactStats(signal);

  const query = useQuery<Awaited<ReturnType<typeof getImpactStats>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's impact report
 */
export const listUserImpactStoryResponse = (signal?: AbortSignal) => {
  return client<ListUserImpactStoriesResponseResponse>({
    url: `/jwt/user/impact_stories`,
    method: 'get',
    signal,
  });
};

export const getListUserImpactStoryResponseQueryKey = () => [`/jwt/user/impact_stories`];

export type ListUserImpactStoryResponseQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUserImpactStoryResponse>>
>;
export type ListUserImpactStoryResponseQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useListUserImpactStoryResponse = <
  TData = Awaited<ReturnType<typeof listUserImpactStoryResponse>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listUserImpactStoryResponse>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListUserImpactStoryResponseQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserImpactStoryResponse>>> = ({
    signal,
  }) => listUserImpactStoryResponse(signal);

  const query = useQuery<Awaited<ReturnType<typeof listUserImpactStoryResponse>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's impact report
 */
export const getUserImpactStory = (impactReportID: string, signal?: AbortSignal) => {
  return client<GetUserImpactStoryResponseResponse>({
    url: `/jwt/user/impact_stories/${impactReportID}`,
    method: 'get',
    signal,
  });
};

export const getGetUserImpactStoryQueryKey = (impactReportID: string) => [
  `/jwt/user/impact_stories/${impactReportID}`,
];

export type GetUserImpactStoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserImpactStory>>
>;
export type GetUserImpactStoryQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserImpactStory = <
  TData = Awaited<ReturnType<typeof getUserImpactStory>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  impactReportID: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserImpactStory>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserImpactStoryQueryKey(impactReportID);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserImpactStory>>> = ({ signal }) =>
    getUserImpactStory(impactReportID, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserImpactStory>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!impactReportID, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Update the users impact report
 */
export const patchUserImpactStory = (impactReportID: string, userImpactStory: UserImpactStory) => {
  return client<GetUserImpactStoryResponseResponse>({
    url: `/jwt/user/impact_stories/${impactReportID}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: userImpactStory,
  });
};

export type PatchUserImpactStoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUserImpactStory>>
>;
export type PatchUserImpactStoryMutationBody = UserImpactStory;
export type PatchUserImpactStoryMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePatchUserImpactStory = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUserImpactStory>>,
    TError,
    { impactReportID: string; data: UserImpactStory },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUserImpactStory>>,
    { impactReportID: string; data: UserImpactStory }
  > = (props) => {
    const { impactReportID, data } = props ?? {};

    return patchUserImpactStory(impactReportID, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof patchUserImpactStory>>,
    TError,
    { impactReportID: string; data: UserImpactStory },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get a jwt required to authenticate a user with Iterable
 */
export const getIterableJwt = (signal?: AbortSignal) => {
  return client<GetIterableJwtResponseResponse>({
    url: `/jwt/user/iterableJwt`,
    method: 'get',
    signal,
  });
};

export const getGetIterableJwtQueryKey = () => [`/jwt/user/iterableJwt`];

export type GetIterableJwtQueryResult = NonNullable<Awaited<ReturnType<typeof getIterableJwt>>>;
export type GetIterableJwtQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetIterableJwt = <
  TData = Awaited<ReturnType<typeof getIterableJwt>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIterableJwt>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIterableJwtQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIterableJwt>>> = ({ signal }) =>
    getIterableJwt(signal);

  const query = useQuery<Awaited<ReturnType<typeof getIterableJwt>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get various limits defined for this user like deposit limit, min amount etc
 */
export const getUserLimits = (signal?: AbortSignal) => {
  return client<UserLimitsResponseResponse>({ url: `/jwt/user/limits`, method: 'get', signal });
};

export const getGetUserLimitsQueryKey = () => [`/jwt/user/limits`];

export type GetUserLimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLimits>>>;
export type GetUserLimitsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserLimits = <
  TData = Awaited<ReturnType<typeof getUserLimits>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserLimits>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserLimitsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLimits>>> = ({ signal }) =>
    getUserLimits(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserLimits>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Create a Plaid Link Token
 */
export const createLinkToken = (params?: CreateLinkTokenParams, signal?: AbortSignal) => {
  return client<CreateLinkTokenResponseResponse>({
    url: `/jwt/user/linkToken`,
    method: 'get',
    params,
    signal,
  });
};

export const getCreateLinkTokenQueryKey = (params?: CreateLinkTokenParams) => [
  `/jwt/user/linkToken`,
  ...(params ? [params] : []),
];

export type CreateLinkTokenQueryResult = NonNullable<Awaited<ReturnType<typeof createLinkToken>>>;
export type CreateLinkTokenQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useCreateLinkToken = <
  TData = Awaited<ReturnType<typeof createLinkToken>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: CreateLinkTokenParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof createLinkToken>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCreateLinkTokenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof createLinkToken>>> = ({ signal }) =>
    createLinkToken(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof createLinkToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This API adds linked bank account to a card, saving it in the database,
note that these accounts have not been verified at this point, and will be registered with our BaaS in a different function
 * @summary addMicrodepositBankAccount
We use this endpoint to save a bank account if the user used either of plaid's microdeposit
account linking options (same day microdeposit, automated micro-deposit)
 */
export const addMicrodepositBankAccount = (
  addNewBankAccountToCardRequestBody: AddNewBankAccountToCardRequestBody,
) => {
  return client<AddNewBankAccountToCardResponseResponse>({
    url: `/jwt/user/microdepositBankAccount`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addNewBankAccountToCardRequestBody,
  });
};

export type AddMicrodepositBankAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof addMicrodepositBankAccount>>
>;
export type AddMicrodepositBankAccountMutationBody = AddNewBankAccountToCardRequestBody;
export type AddMicrodepositBankAccountMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useAddMicrodepositBankAccount = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMicrodepositBankAccount>>,
    TError,
    { data: AddNewBankAccountToCardRequestBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addMicrodepositBankAccount>>,
    { data: AddNewBankAccountToCardRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return addMicrodepositBankAccount(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof addMicrodepositBankAccount>>,
    TError,
    { data: AddNewBankAccountToCardRequestBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get available tree updates for the user
 */
export const getMyTreesUpdates = (signal?: AbortSignal) => {
  return client<MyTreesUpdatesResponseResponse>({
    url: `/jwt/user/my_trees_updates`,
    method: 'get',
    signal,
  });
};

export const getGetMyTreesUpdatesQueryKey = () => [`/jwt/user/my_trees_updates`];

export type GetMyTreesUpdatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMyTreesUpdates>>
>;
export type GetMyTreesUpdatesQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetMyTreesUpdates = <
  TData = Awaited<ReturnType<typeof getMyTreesUpdates>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMyTreesUpdates>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyTreesUpdatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyTreesUpdates>>> = ({ signal }) =>
    getMyTreesUpdates(signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyTreesUpdates>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * # Request body contains the user passsord object
 * @summary updateUserPassword
Update a user's password
 */
export const updateUserPassword = (updateUserPasswordPayload: UpdateUserPasswordPayload) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/password`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateUserPasswordPayload,
  });
};

export type UpdateUserPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserPassword>>
>;
export type UpdateUserPasswordMutationBody = UpdateUserPasswordPayload;
export type UpdateUserPasswordMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useUpdateUserPassword = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserPassword>>,
    TError,
    { data: UpdateUserPasswordPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserPassword>>,
    { data: UpdateUserPasswordPayload }
  > = (props) => {
    const { data } = props ?? {};

    return updateUserPassword(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUserPassword>>,
    TError,
    { data: UpdateUserPasswordPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the users settings for pay whats fair
 */
export const getPayWhatsFair = (signal?: AbortSignal) => {
  return client<PayWhatsFairResponseResponse>({
    url: `/jwt/user/pay_whats_fair`,
    method: 'get',
    signal,
  });
};

export const getGetPayWhatsFairQueryKey = () => [`/jwt/user/pay_whats_fair`];

export type GetPayWhatsFairQueryResult = NonNullable<Awaited<ReturnType<typeof getPayWhatsFair>>>;
export type GetPayWhatsFairQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetPayWhatsFair = <
  TData = Awaited<ReturnType<typeof getPayWhatsFair>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPayWhatsFair>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPayWhatsFairQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayWhatsFair>>> = ({ signal }) =>
    getPayWhatsFair(signal);

  const query = useQuery<Awaited<ReturnType<typeof getPayWhatsFair>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Set the configuration for pay whats fair
 */
export const setPayWhatsFair = (setPayWhatsFairPayload: SetPayWhatsFairPayload) => {
  return client<PayWhatsFairResponseResponse>({
    url: `/jwt/user/pay_whats_fair`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: setPayWhatsFairPayload,
  });
};

export type SetPayWhatsFairMutationResult = NonNullable<
  Awaited<ReturnType<typeof setPayWhatsFair>>
>;
export type SetPayWhatsFairMutationBody = SetPayWhatsFairPayload;
export type SetPayWhatsFairMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetPayWhatsFair = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPayWhatsFair>>,
    TError,
    { data: SetPayWhatsFairPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setPayWhatsFair>>,
    { data: SetPayWhatsFairPayload }
  > = (props) => {
    const { data } = props ?? {};

    return setPayWhatsFair(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setPayWhatsFair>>,
    TError,
    { data: SetPayWhatsFairPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the error from a paymentIntent
 */
export const getPaymentIntentError = (paymentIntentId: string, signal?: AbortSignal) => {
  return client<GetPaymentIntentErrorResponseResponse>({
    url: `/jwt/user/paymentIntent/${paymentIntentId}/error`,
    method: 'get',
    signal,
  });
};

export const getGetPaymentIntentErrorQueryKey = (paymentIntentId: string) => [
  `/jwt/user/paymentIntent/${paymentIntentId}/error`,
];

export type GetPaymentIntentErrorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentIntentError>>
>;
export type GetPaymentIntentErrorQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetPaymentIntentError = <
  TData = Awaited<ReturnType<typeof getPaymentIntentError>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  paymentIntentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentIntentError>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentIntentErrorQueryKey(paymentIntentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentIntentError>>> = ({ signal }) =>
    getPaymentIntentError(paymentIntentId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentIntentError>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!paymentIntentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Returns whether a user can skip the plaid status in onboarding
 */
export const canUserSkipPlaid = (signal?: AbortSignal) => {
  return client<CanUserSkipPlaidResponseResponse>({
    url: `/jwt/user/plaid/canSkip`,
    method: 'get',
    signal,
  });
};

export const getCanUserSkipPlaidQueryKey = () => [`/jwt/user/plaid/canSkip`];

export type CanUserSkipPlaidQueryResult = NonNullable<Awaited<ReturnType<typeof canUserSkipPlaid>>>;
export type CanUserSkipPlaidQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useCanUserSkipPlaid = <
  TData = Awaited<ReturnType<typeof canUserSkipPlaid>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof canUserSkipPlaid>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCanUserSkipPlaidQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof canUserSkipPlaid>>> = ({ signal }) =>
    canUserSkipPlaid(signal);

  const query = useQuery<Awaited<ReturnType<typeof canUserSkipPlaid>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Plant a number of trees with an allowed reason
 */
export const plantTrees = (plantTreesBody: PlantTreesBody) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/plantTrees`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: plantTreesBody,
  });
};

export type PlantTreesMutationResult = NonNullable<Awaited<ReturnType<typeof plantTrees>>>;
export type PlantTreesMutationBody = PlantTreesBody;
export type PlantTreesMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePlantTrees = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof plantTrees>>,
    TError,
    { data: PlantTreesBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof plantTrees>>,
    { data: PlantTreesBody }
  > = (props) => {
    const { data } = props ?? {};

    return plantTrees(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof plantTrees>>,
    TError,
    { data: PlantTreesBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * potSummary
List pots for a user
 */
export const potSummary = (signal?: AbortSignal) => {
  return client<PotSummaryResponseResponse>({ url: `/jwt/user/pots`, method: 'get', signal });
};

export const getPotSummaryQueryKey = () => [`/jwt/user/pots`];

export type PotSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof potSummary>>>;
export type PotSummaryQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const usePotSummary = <
  TData = Awaited<ReturnType<typeof potSummary>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof potSummary>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPotSummaryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof potSummary>>> = ({ signal }) =>
    potSummary(signal);

  const query = useQuery<Awaited<ReturnType<typeof potSummary>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * # Sets up a new pot
 * @summary createPot
Create a pot
 */
export const createPot = (createPotBody: CreatePotBody) => {
  return client<PotResponseResponse>({
    url: `/jwt/user/pots`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createPotBody,
  });
};

export type CreatePotMutationResult = NonNullable<Awaited<ReturnType<typeof createPot>>>;
export type CreatePotMutationBody = CreatePotBody;
export type CreatePotMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCreatePot = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPot>>,
    TError,
    { data: CreatePotBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPot>>,
    { data: CreatePotBody }
  > = (props) => {
    const { data } = props ?? {};

    return createPot(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createPot>>,
    TError,
    { data: CreatePotBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * potDetails
Read pot details
 */
export const potDetails = (potId: string, signal?: AbortSignal) => {
  return client<PotDetailsResponseResponse>({
    url: `/jwt/user/pots/${potId}`,
    method: 'get',
    signal,
  });
};

export const getPotDetailsQueryKey = (potId: string) => [`/jwt/user/pots/${potId}`];

export type PotDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof potDetails>>>;
export type PotDetailsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const usePotDetails = <
  TData = Awaited<ReturnType<typeof potDetails>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  potId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof potDetails>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPotDetailsQueryKey(potId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof potDetails>>> = ({ signal }) =>
    potDetails(potId, signal);

  const query = useQuery<Awaited<ReturnType<typeof potDetails>>, TError, TData>(queryKey, queryFn, {
    enabled: !!potId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Deletes a pot
 */
export const deletePot = (potId: string) => {
  return client<EmptyResponseResponse>({ url: `/jwt/user/pots/${potId}`, method: 'delete' });
};

export type DeletePotMutationResult = NonNullable<Awaited<ReturnType<typeof deletePot>>>;

export type DeletePotMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useDeletePot = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePot>>,
    TError,
    { potId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePot>>, { potId: string }> = (
    props,
  ) => {
    const { potId } = props ?? {};

    return deletePot(potId);
  };

  return useMutation<Awaited<ReturnType<typeof deletePot>>, TError, { potId: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary Updates a pot
 */
export const updatePot = (potId: string, updatePotBody: UpdatePotBody) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/pots/${potId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updatePotBody,
  });
};

export type UpdatePotMutationResult = NonNullable<Awaited<ReturnType<typeof updatePot>>>;
export type UpdatePotMutationBody = UpdatePotBody;
export type UpdatePotMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useUpdatePot = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePot>>,
    TError,
    { potId: string; data: UpdatePotBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePot>>,
    { potId: string; data: UpdatePotBody }
  > = (props) => {
    const { potId, data } = props ?? {};

    return updatePot(potId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updatePot>>,
    TError,
    { potId: string; data: UpdatePotBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Add funds to a pot
 */
export const potAddFunds = (potId: string, potTransfer: PotTransfer) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/pots/${potId}/addFunds`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: potTransfer,
  });
};

export type PotAddFundsMutationResult = NonNullable<Awaited<ReturnType<typeof potAddFunds>>>;
export type PotAddFundsMutationBody = PotTransfer;
export type PotAddFundsMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePotAddFunds = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof potAddFunds>>,
    TError,
    { potId: string; data: PotTransfer },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof potAddFunds>>,
    { potId: string; data: PotTransfer }
  > = (props) => {
    const { potId, data } = props ?? {};

    return potAddFunds(potId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof potAddFunds>>,
    TError,
    { potId: string; data: PotTransfer },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary List pot transactions
 */
export const potTransactions = (potId: string, signal?: AbortSignal) => {
  return client<PotTransactionsResponseResponse>({
    url: `/jwt/user/pots/${potId}/transactions`,
    method: 'get',
    signal,
  });
};

export const getPotTransactionsQueryKey = (potId: string) => [
  `/jwt/user/pots/${potId}/transactions`,
];

export type PotTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof potTransactions>>>;
export type PotTransactionsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const usePotTransactions = <
  TData = Awaited<ReturnType<typeof potTransactions>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  potId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof potTransactions>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPotTransactionsQueryKey(potId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof potTransactions>>> = ({ signal }) =>
    potTransactions(potId, signal);

  const query = useQuery<Awaited<ReturnType<typeof potTransactions>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!potId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Withdraw funds from a pot
 */
export const potWithdrawFunds = (potId: string, potWithdrawFundsBody: PotWithdrawFundsBody) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/pots/${potId}/withdrawFunds`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: potWithdrawFundsBody,
  });
};

export type PotWithdrawFundsMutationResult = NonNullable<
  Awaited<ReturnType<typeof potWithdrawFunds>>
>;
export type PotWithdrawFundsMutationBody = PotWithdrawFundsBody;
export type PotWithdrawFundsMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePotWithdrawFunds = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof potWithdrawFunds>>,
    TError,
    { potId: string; data: PotWithdrawFundsBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof potWithdrawFunds>>,
    { potId: string; data: PotWithdrawFundsBody }
  > = (props) => {
    const { potId, data } = props ?? {};

    return potWithdrawFunds(potId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof potWithdrawFunds>>,
    TError,
    { potId: string; data: PotWithdrawFundsBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the notification preferences and the device token
 */
export const getNotificationPreferences = (signal?: AbortSignal) => {
  return client<NotificationPreferencesResponseResponse>({
    url: `/jwt/user/preferences/notification`,
    method: 'get',
    signal,
  });
};

export const getGetNotificationPreferencesQueryKey = () => [`/jwt/user/preferences/notification`];

export type GetNotificationPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotificationPreferences>>
>;
export type GetNotificationPreferencesQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetNotificationPreferences = <
  TData = Awaited<ReturnType<typeof getNotificationPreferences>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNotificationPreferences>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationPreferencesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationPreferences>>> = ({
    signal,
  }) => getNotificationPreferences(signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotificationPreferences>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Set the notification preferences and the device token
 */
export const setNotificationPreferences = (notificationPreferences: NotificationPreferences) => {
  return client<NotificationPreferencesResponseResponse>({
    url: `/jwt/user/preferences/notification`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: notificationPreferences,
  });
};

export type SetNotificationPreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof setNotificationPreferences>>
>;
export type SetNotificationPreferencesMutationBody = NotificationPreferences;
export type SetNotificationPreferencesMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetNotificationPreferences = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setNotificationPreferences>>,
    TError,
    { data: NotificationPreferences },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setNotificationPreferences>>,
    { data: NotificationPreferences }
  > = (props) => {
    const { data } = props ?? {};

    return setNotificationPreferences(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setNotificationPreferences>>,
    TError,
    { data: NotificationPreferences },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Claims the specified reward
 */
export const claimReferralReward = (claimReferralRewardRequest: ClaimReferralRewardRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/referralRewards/claim`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: claimReferralRewardRequest,
  });
};

export type ClaimReferralRewardMutationResult = NonNullable<
  Awaited<ReturnType<typeof claimReferralReward>>
>;
export type ClaimReferralRewardMutationBody = ClaimReferralRewardRequest;
export type ClaimReferralRewardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useClaimReferralReward = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimReferralReward>>,
    TError,
    { data: ClaimReferralRewardRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof claimReferralReward>>,
    { data: ClaimReferralRewardRequest }
  > = (props) => {
    const { data } = props ?? {};

    return claimReferralReward(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof claimReferralReward>>,
    TError,
    { data: ClaimReferralRewardRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the user's referral tier data for a given product
 */
export const getUserReferralTiers = (params?: GetUserReferralTiersParams, signal?: AbortSignal) => {
  return client<GetUserReferralTiersResponseResponse>({
    url: `/jwt/user/referral_tiers`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetUserReferralTiersQueryKey = (params?: GetUserReferralTiersParams) => [
  `/jwt/user/referral_tiers`,
  ...(params ? [params] : []),
];

export type GetUserReferralTiersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserReferralTiers>>
>;
export type GetUserReferralTiersQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserReferralTiers = <
  TData = Awaited<ReturnType<typeof getUserReferralTiers>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetUserReferralTiersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserReferralTiers>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserReferralTiersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserReferralTiers>>> = ({ signal }) =>
    getUserReferralTiers(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserReferralTiers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's referred users
 */
export const getUserReferrals = (signal?: AbortSignal) => {
  return client<UserReferralsResponseResponse>({
    url: `/jwt/user/referrals`,
    method: 'get',
    signal,
  });
};

export const getGetUserReferralsQueryKey = () => [`/jwt/user/referrals`];

export type GetUserReferralsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserReferrals>>>;
export type GetUserReferralsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserReferrals = <
  TData = Awaited<ReturnType<typeof getUserReferrals>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserReferrals>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserReferralsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserReferrals>>> = ({ signal }) =>
    getUserReferrals(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserReferrals>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get user referrer details
 */
export const getUserReferrer = (signal?: AbortSignal) => {
  return client<UserReferrerResponseResponse>({
    url: `/jwt/user/referrerDetails`,
    method: 'get',
    signal,
  });
};

export const getGetUserReferrerQueryKey = () => [`/jwt/user/referrerDetails`];

export type GetUserReferrerQueryResult = NonNullable<Awaited<ReturnType<typeof getUserReferrer>>>;
export type GetUserReferrerQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserReferrer = <
  TData = Awaited<ReturnType<typeof getUserReferrer>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserReferrer>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserReferrerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserReferrer>>> = ({ signal }) =>
    getUserReferrer(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserReferrer>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Claims the specified reward
 */
export const claimReward = (claimRewardRequest: ClaimRewardRequest) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/rewards/claim`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: claimRewardRequest,
  });
};

export type ClaimRewardMutationResult = NonNullable<Awaited<ReturnType<typeof claimReward>>>;
export type ClaimRewardMutationBody = ClaimRewardRequest;
export type ClaimRewardMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useClaimReward = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimReward>>,
    TError,
    { data: ClaimRewardRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof claimReward>>,
    { data: ClaimRewardRequest }
  > = (props) => {
    const { data } = props ?? {};

    return claimReward(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof claimReward>>,
    TError,
    { data: ClaimRewardRequest },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Returns all the rewards a user has enough points to claim
 */
export const getClaimableRewards = (signal?: AbortSignal) => {
  return client<ClaimableRewardsResponseResponse>({
    url: `/jwt/user/rewards/claimable`,
    method: 'get',
    signal,
  });
};

export const getGetClaimableRewardsQueryKey = () => [`/jwt/user/rewards/claimable`];

export type GetClaimableRewardsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClaimableRewards>>
>;
export type GetClaimableRewardsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetClaimableRewards = <
  TData = Awaited<ReturnType<typeof getClaimableRewards>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getClaimableRewards>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClaimableRewardsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClaimableRewards>>> = ({ signal }) =>
    getClaimableRewards(signal);

  const query = useQuery<Awaited<ReturnType<typeof getClaimableRewards>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's available reward point balance
 */
export const getRewardPoints = (signal?: AbortSignal) => {
  return client<RewardPointsResponseResponse>({
    url: `/jwt/user/rewards/points`,
    method: 'get',
    signal,
  });
};

export const getGetRewardPointsQueryKey = () => [`/jwt/user/rewards/points`];

export type GetRewardPointsQueryResult = NonNullable<Awaited<ReturnType<typeof getRewardPoints>>>;
export type GetRewardPointsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetRewardPoints = <
  TData = Awaited<ReturnType<typeof getRewardPoints>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRewardPoints>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardPointsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewardPoints>>> = ({ signal }) =>
    getRewardPoints(signal);

  const query = useQuery<Awaited<ReturnType<typeof getRewardPoints>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's reward transaction history
 */
export const getRewardTransactions = (signal?: AbortSignal) => {
  return client<RewardTransactionsResponseResponse>({
    url: `/jwt/user/rewards/transactions`,
    method: 'get',
    signal,
  });
};

export const getGetRewardTransactionsQueryKey = () => [`/jwt/user/rewards/transactions`];

export type GetRewardTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRewardTransactions>>
>;
export type GetRewardTransactionsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetRewardTransactions = <
  TData = Awaited<ReturnType<typeof getRewardTransactions>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRewardTransactions>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardTransactionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewardTransactions>>> = ({ signal }) =>
    getRewardTransactions(signal);

  const query = useQuery<Awaited<ReturnType<typeof getRewardTransactions>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create a Plaid Link Token specifically for the Same Day Microdeposit flow
This will initialize a different link flow for verifying the microdeposits.
 */
export const sameDayMicrodepositLinkToken = (signal?: AbortSignal) => {
  return client<CreateLinkTokenResponseResponse>({
    url: `/jwt/user/sameDayMicrodepositLinkToken`,
    method: 'get',
    signal,
  });
};

export const getSameDayMicrodepositLinkTokenQueryKey = () => [
  `/jwt/user/sameDayMicrodepositLinkToken`,
];

export type SameDayMicrodepositLinkTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof sameDayMicrodepositLinkToken>>
>;
export type SameDayMicrodepositLinkTokenQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useSameDayMicrodepositLinkToken = <
  TData = Awaited<ReturnType<typeof sameDayMicrodepositLinkToken>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof sameDayMicrodepositLinkToken>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSameDayMicrodepositLinkTokenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sameDayMicrodepositLinkToken>>> = ({
    signal,
  }) => sameDayMicrodepositLinkToken(signal);

  const query = useQuery<Awaited<ReturnType<typeof sameDayMicrodepositLinkToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Returns a Pdf for the given date range
 */
export const getStatementPdf = (params: GetStatementPdfParams, signal?: AbortSignal) => {
  return client<GetStatementPdfResponseResponse>({
    url: `/jwt/user/statementPdf`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetStatementPdfQueryKey = (params: GetStatementPdfParams) => [
  `/jwt/user/statementPdf`,
  ...(params ? [params] : []),
];

export type GetStatementPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getStatementPdf>>>;
export type GetStatementPdfQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetStatementPdf = <
  TData = Awaited<ReturnType<typeof getStatementPdf>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params: GetStatementPdfParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getStatementPdf>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStatementPdfQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatementPdf>>> = ({ signal }) =>
    getStatementPdf(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getStatementPdf>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the user's available statement periods starting when their first card was opened
 */
export const getUserStatementPeriods = (signal?: AbortSignal) => {
  return client<UserStatementPeriodsResponseResponse>({
    url: `/jwt/user/statementPeriods`,
    method: 'get',
    signal,
  });
};

export const getGetUserStatementPeriodsQueryKey = () => [`/jwt/user/statementPeriods`];

export type GetUserStatementPeriodsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserStatementPeriods>>
>;
export type GetUserStatementPeriodsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetUserStatementPeriods = <
  TData = Awaited<ReturnType<typeof getUserStatementPeriods>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserStatementPeriods>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserStatementPeriodsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserStatementPeriods>>> = ({
    signal,
  }) => getUserStatementPeriods(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserStatementPeriods>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * # This API returns the AutoTopup and ScheduledTopup preferences for a user
 * @summary getTopupPreferences
Get topup preferences associated with that user
 */
export const getTopupPreferences = (signal?: AbortSignal) => {
  return client<GetTopupPreferencesResponseResponse>({
    url: `/jwt/user/topupPreferences`,
    method: 'get',
    signal,
  });
};

export const getGetTopupPreferencesQueryKey = () => [`/jwt/user/topupPreferences`];

export type GetTopupPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTopupPreferences>>
>;
export type GetTopupPreferencesQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTopupPreferences = <
  TData = Awaited<ReturnType<typeof getTopupPreferences>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopupPreferences>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopupPreferencesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopupPreferences>>> = ({ signal }) =>
    getTopupPreferences(signal);

  const query = useQuery<Awaited<ReturnType<typeof getTopupPreferences>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * # This API sets the AutoTopup and ScheduledTopup preferences for a user
 * @summary setTopupPreferences
Set topup preferences associated with that user
 */
export const setTopupPreferences = (setTopupPreferencesPayload: SetTopupPreferencesPayload) => {
  return client<SetTopupPreferencesResponseResponse>({
    url: `/jwt/user/topupPreferences`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: setTopupPreferencesPayload,
  });
};

export type SetTopupPreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof setTopupPreferences>>
>;
export type SetTopupPreferencesMutationBody = SetTopupPreferencesPayload;
export type SetTopupPreferencesMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetTopupPreferences = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setTopupPreferences>>,
    TError,
    { data: SetTopupPreferencesPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setTopupPreferences>>,
    { data: SetTopupPreferencesPayload }
  > = (props) => {
    const { data } = props ?? {};

    return setTopupPreferences(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setTopupPreferences>>,
    TError,
    { data: SetTopupPreferencesPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * getTransactionsSpendSummary
Get amount of spending in a given month
 */
export const getTransactionsSpendSummary = (
  params?: GetTransactionsSpendSummaryParams,
  signal?: AbortSignal,
) => {
  return client<TransactionsSpendSummaryResponseResponse>({
    url: `/jwt/user/transactions/spendSummary`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetTransactionsSpendSummaryQueryKey = (
  params?: GetTransactionsSpendSummaryParams,
) => [`/jwt/user/transactions/spendSummary`, ...(params ? [params] : [])];

export type GetTransactionsSpendSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionsSpendSummary>>
>;
export type GetTransactionsSpendSummaryQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTransactionsSpendSummary = <
  TData = Awaited<ReturnType<typeof getTransactionsSpendSummary>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetTransactionsSpendSummaryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionsSpendSummary>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsSpendSummaryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsSpendSummary>>> = ({
    signal,
  }) => getTransactionsSpendSummary(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransactionsSpendSummary>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * # This API accepts a transactionID as a query
 * @summary getTransactionDetails
Get detailed information on a specific transaction
 */
export const getTransactionDetails = (transactionId: string, signal?: AbortSignal) => {
  return client<TransactionDetailsResponseResponse>({
    url: `/jwt/user/transactions/${transactionId}/details`,
    method: 'get',
    signal,
  });
};

export const getGetTransactionDetailsQueryKey = (transactionId: string) => [
  `/jwt/user/transactions/${transactionId}/details`,
];

export type GetTransactionDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionDetails>>
>;
export type GetTransactionDetailsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTransactionDetails = <
  TData = Awaited<ReturnType<typeof getTransactionDetails>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  transactionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionDetails>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionDetailsQueryKey(transactionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionDetails>>> = ({ signal }) =>
    getTransactionDetails(transactionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransactionDetails>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!transactionId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * This API accepts a transactionID in the path.
 * @summary hideTransaction
Mark a failed transaction as hidden, so the user no longer sees it in their transaction list
 */
export const hideTransaction = (transactionId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/transactions/${transactionId}/hide`,
    method: 'post',
  });
};

export type HideTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof hideTransaction>>
>;

export type HideTransactionMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useHideTransaction = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof hideTransaction>>,
    TError,
    { transactionId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof hideTransaction>>,
    { transactionId: string }
  > = (props) => {
    const { transactionId } = props ?? {};

    return hideTransaction(transactionId);
  };

  return useMutation<
    Awaited<ReturnType<typeof hideTransaction>>,
    TError,
    { transactionId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the user's number of trees planted
 */
export const getTreesPlanted = (params?: GetTreesPlantedParams, signal?: AbortSignal) => {
  return client<TreesPlantedResponseResponse>({
    url: `/jwt/user/treesPlanted`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetTreesPlantedQueryKey = (params?: GetTreesPlantedParams) => [
  `/jwt/user/treesPlanted`,
  ...(params ? [params] : []),
];

export type GetTreesPlantedQueryResult = NonNullable<Awaited<ReturnType<typeof getTreesPlanted>>>;
export type GetTreesPlantedQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTreesPlanted = <
  TData = Awaited<ReturnType<typeof getTreesPlanted>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetTreesPlantedParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTreesPlanted>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTreesPlantedQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTreesPlanted>>> = ({ signal }) =>
    getTreesPlanted(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTreesPlanted>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * patchZendesk
Takes a zendesk user ID and pushes the sycamore ID and user email to zendesk
 */
export const patchZendesk = (patchZendeskPayload: PatchZendeskPayload) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/user/zendesk`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: patchZendeskPayload,
  });
};

export type PatchZendeskMutationResult = NonNullable<Awaited<ReturnType<typeof patchZendesk>>>;
export type PatchZendeskMutationBody = PatchZendeskPayload;
export type PatchZendeskMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const usePatchZendesk = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchZendesk>>,
    TError,
    { data: PatchZendeskPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchZendesk>>,
    { data: PatchZendeskPayload }
  > = (props) => {
    const { data } = props ?? {};

    return patchZendesk(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof patchZendesk>>,
    TError,
    { data: PatchZendeskPayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get a jwt required to authenticate a user with Zendesk
 */
export const getZendeskJwt = (signal?: AbortSignal) => {
  return client<GetZendeskJwtResponseResponse>({
    url: `/jwt/user/zendeskJwt`,
    method: 'get',
    signal,
  });
};

export const getGetZendeskJwtQueryKey = () => [`/jwt/user/zendeskJwt`];

export type GetZendeskJwtQueryResult = NonNullable<Awaited<ReturnType<typeof getZendeskJwt>>>;
export type GetZendeskJwtQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetZendeskJwt = <
  TData = Awaited<ReturnType<typeof getZendeskJwt>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getZendeskJwt>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetZendeskJwtQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getZendeskJwt>>> = ({ signal }) =>
    getZendeskJwt(signal);

  const query = useQuery<Awaited<ReturnType<typeof getZendeskJwt>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Lists the screenshots for the user
 */
export const listTreeValleyScreenshots = (signal?: AbortSignal) => {
  return client<unknown>({ url: `/jwt/v2/tree_valley/screenshots`, method: 'get', signal });
};

export const getListTreeValleyScreenshotsQueryKey = () => [`/jwt/v2/tree_valley/screenshots`];

export type ListTreeValleyScreenshotsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTreeValleyScreenshots>>
>;
export type ListTreeValleyScreenshotsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useListTreeValleyScreenshots = <
  TData = Awaited<ReturnType<typeof listTreeValleyScreenshots>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listTreeValleyScreenshots>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTreeValleyScreenshotsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTreeValleyScreenshots>>> = ({
    signal,
  }) => listTreeValleyScreenshots(signal);

  const query = useQuery<Awaited<ReturnType<typeof listTreeValleyScreenshots>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Expects a form with a field of screenshot containing an image file.
 * @summary # Set the state of one or more tree valley tiles.
 */
export const createTreeValleyScreenshot = () => {
  return client<CreateTreeValleyScreenshotResponseResponse>({
    url: `/jwt/v2/tree_valley/screenshots`,
    method: 'post',
  });
};

export type CreateTreeValleyScreenshotMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTreeValleyScreenshot>>
>;

export type CreateTreeValleyScreenshotMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useCreateTreeValleyScreenshot = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTreeValleyScreenshot>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTreeValleyScreenshot>>,
    TVariables
  > = () => {
    return createTreeValleyScreenshot();
  };

  return useMutation<
    Awaited<ReturnType<typeof createTreeValleyScreenshot>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the current tree valley tiles state for this user
 */
export const getTreeValleyState = (signal?: AbortSignal) => {
  return client<TreeValleyStateResponseResponse>({
    url: `/jwt/v2/tree_valley/state`,
    method: 'get',
    signal,
  });
};

export const getGetTreeValleyStateQueryKey = () => [`/jwt/v2/tree_valley/state`];

export type GetTreeValleyStateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTreeValleyState>>
>;
export type GetTreeValleyStateQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTreeValleyState = <
  TData = Awaited<ReturnType<typeof getTreeValleyState>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTreeValleyState>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTreeValleyStateQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTreeValleyState>>> = ({ signal }) =>
    getTreeValleyState(signal);

  const query = useQuery<Awaited<ReturnType<typeof getTreeValleyState>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Remove a piece of plastic from the state
 */
export const removePlastic = () => {
  return client<EmptyResponseResponse>({
    url: `/jwt/v2/tree_valley/state/removePlastic`,
    method: 'post',
  });
};

export type RemovePlasticMutationResult = NonNullable<Awaited<ReturnType<typeof removePlastic>>>;

export type RemovePlasticMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useRemovePlastic = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removePlastic>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removePlastic>>,
    TVariables
  > = () => {
    return removePlastic();
  };

  return useMutation<Awaited<ReturnType<typeof removePlastic>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * @summary Set the state of one or more tree valley tiles
 */
export const setTreeValleyTiles = (unityTreeValleyTile: UnityTreeValleyTile[]) => {
  return client<TreeValleyTilesResponseResponse>({
    url: `/jwt/v2/tree_valley/state/tiles`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: unityTreeValleyTile,
  });
};

export type SetTreeValleyTilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof setTreeValleyTiles>>
>;
export type SetTreeValleyTilesMutationBody = UnityTreeValleyTile[];
export type SetTreeValleyTilesMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useSetTreeValleyTiles = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setTreeValleyTiles>>,
    TError,
    { data: UnityTreeValleyTile[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setTreeValleyTiles>>,
    { data: UnityTreeValleyTile[] }
  > = (props) => {
    const { data } = props ?? {};

    return setTreeValleyTiles(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof setTreeValleyTiles>>,
    TError,
    { data: UnityTreeValleyTile[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Claims the bonus points available for this tile. The tileId in the URL is the game tile id, not the database tile id.
 */
export const claimTreeBonusPoints = (tileId: string) => {
  return client<EmptyResponseResponse>({
    url: `/jwt/v2/tree_valley/state/tiles/${tileId}/claimPoints`,
    method: 'post',
  });
};

export type ClaimTreeBonusPointsMutationResult = NonNullable<
  Awaited<ReturnType<typeof claimTreeBonusPoints>>
>;

export type ClaimTreeBonusPointsMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useClaimTreeBonusPoints = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimTreeBonusPoints>>,
    TError,
    { tileId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof claimTreeBonusPoints>>,
    { tileId: string }
  > = (props) => {
    const { tileId } = props ?? {};

    return claimTreeBonusPoints(tileId);
  };

  return useMutation<
    Awaited<ReturnType<typeof claimTreeBonusPoints>>,
    TError,
    { tileId: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get the unclaimed tree valley related points for a user
 */
export const getTreeValleyUnclaimedPoints = (signal?: AbortSignal) => {
  return client<TreeValleyUnclaimedPointsResponseResponse>({
    url: `/jwt/v2/tree_valley/unclaimed_points`,
    method: 'get',
    signal,
  });
};

export const getGetTreeValleyUnclaimedPointsQueryKey = () => [
  `/jwt/v2/tree_valley/unclaimed_points`,
];

export type GetTreeValleyUnclaimedPointsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTreeValleyUnclaimedPoints>>
>;
export type GetTreeValleyUnclaimedPointsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetTreeValleyUnclaimedPoints = <
  TData = Awaited<ReturnType<typeof getTreeValleyUnclaimedPoints>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTreeValleyUnclaimedPoints>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTreeValleyUnclaimedPointsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTreeValleyUnclaimedPoints>>> = ({
    signal,
  }) => getTreeValleyUnclaimedPoints(signal);

  const query = useQuery<Awaited<ReturnType<typeof getTreeValleyUnclaimedPoints>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Lists the map locations
 */
export const listMapLocations = (signal?: AbortSignal) => {
  return client<ListMapLocationsResponseResponse>({
    url: `/public/map/locations`,
    method: 'get',
    signal,
  });
};

export const getListMapLocationsQueryKey = () => [`/public/map/locations`];

export type ListMapLocationsQueryResult = NonNullable<Awaited<ReturnType<typeof listMapLocations>>>;
export type ListMapLocationsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useListMapLocations = <
  TData = Awaited<ReturnType<typeof listMapLocations>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listMapLocations>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMapLocationsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listMapLocations>>> = ({ signal }) =>
    listMapLocations(signal);

  const query = useQuery<Awaited<ReturnType<typeof listMapLocations>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get a users profile
 */
export const getPublicProfile = (userId: string, signal?: AbortSignal) => {
  return client<PublicProfile>({ url: `/public/profiles/${userId}`, method: 'get', signal });
};

export const getGetPublicProfileQueryKey = (userId: string) => [`/public/profiles/${userId}`];

export type GetPublicProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicProfile>>>;
export type GetPublicProfileQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetPublicProfile = <
  TData = Awaited<ReturnType<typeof getPublicProfile>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicProfile>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPublicProfileQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicProfile>>> = ({ signal }) =>
    getPublicProfile(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPublicProfile>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!userId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Validates the provided referral code is valid
 */
export const validateReferralCode = (validateReferalCodePayload: ValidateReferalCodePayload) => {
  return client<EmptyResponseResponse>({
    url: `/referralCodes/validate`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: validateReferalCodePayload,
  });
};

export type ValidateReferralCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateReferralCode>>
>;
export type ValidateReferralCodeMutationBody = ValidateReferalCodePayload;
export type ValidateReferralCodeMutationError = ErrorType<SycamoreErrorResponseResponse>;

export const useValidateReferralCode = <
  TError = ErrorType<SycamoreErrorResponseResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateReferralCode>>,
    TError,
    { data: ValidateReferalCodePayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateReferralCode>>,
    { data: ValidateReferalCodePayload }
  > = (props) => {
    const { data } = props ?? {};

    return validateReferralCode(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof validateReferralCode>>,
    TError,
    { data: ValidateReferalCodePayload },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get available rewards, and optionally filter by RewardCategory
 */
export const getRewards = (params?: GetRewardsParams, signal?: AbortSignal) => {
  return client<GetRewardsResponseResponse>({ url: `/rewards`, method: 'get', params, signal });
};

export const getGetRewardsQueryKey = (params?: GetRewardsParams) => [
  `/rewards`,
  ...(params ? [params] : []),
];

export type GetRewardsQueryResult = NonNullable<Awaited<ReturnType<typeof getRewards>>>;
export type GetRewardsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetRewards = <
  TData = Awaited<ReturnType<typeof getRewards>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetRewardsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getRewards>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewards>>> = ({ signal }) =>
    getRewards(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRewards>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get CMS content for reward teasers
 */
export const getRewardTeasers = (signal?: AbortSignal) => {
  return client<GetRewardTeasersResponseResponse>({
    url: `/rewards/teasers`,
    method: 'get',
    signal,
  });
};

export const getGetRewardTeasersQueryKey = () => [`/rewards/teasers`];

export type GetRewardTeasersQueryResult = NonNullable<Awaited<ReturnType<typeof getRewardTeasers>>>;
export type GetRewardTeasersQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetRewardTeasers = <
  TData = Awaited<ReturnType<typeof getRewardTeasers>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRewardTeasers>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardTeasersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewardTeasers>>> = ({ signal }) =>
    getRewardTeasers(signal);

  const query = useQuery<Awaited<ReturnType<typeof getRewardTeasers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get impact stats for the treecard community
 */
export const getCommunityImpactStats = (signal?: AbortSignal) => {
  return client<CommunityImpactStatsResponseResponse>({
    url: `/stats/communityImpactStats`,
    method: 'get',
    signal,
  });
};

export const getGetCommunityImpactStatsQueryKey = () => [`/stats/communityImpactStats`];

export type GetCommunityImpactStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommunityImpactStats>>
>;
export type GetCommunityImpactStatsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetCommunityImpactStats = <
  TData = Awaited<ReturnType<typeof getCommunityImpactStats>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCommunityImpactStats>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCommunityImpactStatsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityImpactStats>>> = ({
    signal,
  }) => getCommunityImpactStats(signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityImpactStats>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get impact stats for the treecard community
 */
export const getDiscoverImpactStats = (
  params?: GetDiscoverImpactStatsParams,
  signal?: AbortSignal,
) => {
  return client<DiscoverImpactStatsResponseResponse>({
    url: `/stats/discoverImpactStats`,
    method: 'get',
    params,
    signal,
  });
};

export const getGetDiscoverImpactStatsQueryKey = (params?: GetDiscoverImpactStatsParams) => [
  `/stats/discoverImpactStats`,
  ...(params ? [params] : []),
];

export type GetDiscoverImpactStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDiscoverImpactStats>>
>;
export type GetDiscoverImpactStatsQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetDiscoverImpactStats = <
  TData = Awaited<ReturnType<typeof getDiscoverImpactStats>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(
  params?: GetDiscoverImpactStatsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDiscoverImpactStats>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscoverImpactStatsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiscoverImpactStats>>> = ({ signal }) =>
    getDiscoverImpactStats(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDiscoverImpactStats>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get the number of trees planted by all treecard users with optional date filters
 */
export const getGlobalTreesPlanted = (signal?: AbortSignal) => {
  return client<GlobalTreesPlantedResponseResponse>({
    url: `/stats/treesPlanted`,
    method: 'get',
    signal,
  });
};

export const getGetGlobalTreesPlantedQueryKey = () => [`/stats/treesPlanted`];

export type GetGlobalTreesPlantedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGlobalTreesPlanted>>
>;
export type GetGlobalTreesPlantedQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useGetGlobalTreesPlanted = <
  TData = Awaited<ReturnType<typeof getGlobalTreesPlanted>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getGlobalTreesPlanted>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGlobalTreesPlantedQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGlobalTreesPlanted>>> = ({ signal }) =>
    getGlobalTreesPlanted(signal);

  const query = useQuery<Awaited<ReturnType<typeof getGlobalTreesPlanted>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * treePlantingExchangeRate
Get the exchange rate that shows the dollar amount of spend that plants one tree.
E.g.: If points earned per dollar spent is 2, and point cost to plant a tree is 100, this rate is $50.00
 */
export const treePlantingExchangeRate = (signal?: AbortSignal) => {
  return client<TreePlantingExchangeRateResponseResponse>({
    url: `/treePlantingExchangeRate`,
    method: 'get',
    signal,
  });
};

export const getTreePlantingExchangeRateQueryKey = () => [`/treePlantingExchangeRate`];

export type TreePlantingExchangeRateQueryResult = NonNullable<
  Awaited<ReturnType<typeof treePlantingExchangeRate>>
>;
export type TreePlantingExchangeRateQueryError = ErrorType<SycamoreErrorResponseResponse>;

export const useTreePlantingExchangeRate = <
  TData = Awaited<ReturnType<typeof treePlantingExchangeRate>>,
  TError = ErrorType<SycamoreErrorResponseResponse>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof treePlantingExchangeRate>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTreePlantingExchangeRateQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof treePlantingExchangeRate>>> = ({
    signal,
  }) => treePlantingExchangeRate(signal);

  const query = useQuery<Awaited<ReturnType<typeof treePlantingExchangeRate>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
