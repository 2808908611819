import React from 'react';

import DesktopBG from '@assets/images/footer/d-signup-bg.jpg';
import MobileBG from '@assets/images/footer/m-signup-bg.jpg';
import Icon from '@components/Icon';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import formatMessage from 'format-message';
import { Col, Container, Row, Button, Image } from 'react-bootstrap';

interface FooterHeroProps {
  referralCode: string;
  ctaClick: () => void;
}

const messages = {
  heroHeading: 'Sign up to grow your own impact at no cost.',
  heroDescription: "Use my referral code {referralCode} and we'll both earn 2 trees.",
  heroTime: 'Takes 90s',
  heroButtonCTA: 'Sign up now',
};

const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: '#e5edf8',
  },
  referralCode: {
    color: colors.success,
    fontFamily: fonts.semiBold,
  },
  heroHeading: {
    fontSize: '30px',
    lineHeight: '40px',
  },
  heroDescription: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: '500',
  },
  heroTime: {
    fontSize: '16px',
  },
  heroButton: {
    width: '100%',
  },
});

const mediumScreenStyles = StyleSheet.create({
  heroHeading: {
    fontSize: '60px',
    lineHeight: '72px',
  },
  heroDescription: {
    fontSize: '25px',
    lineHeight: '35px',
  },
  heroTime: {
    fontSize: '20px',
  },
  heroButton: {
    width: 'auto',
  },
  heroClockIcon: {
    width: '20px',
    height: '20px',
  },
});

const FooterHero: React.FC<FooterHeroProps> = ({ referralCode, ctaClick }) => {
  const mediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);

  return (
    <section style={styles.sectionContainer}>
      <Container className='pt-4 pt-lg-5' data-testid='footer_container'>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h2
              className='font-semiBold secondary'
              style={{
                ...styles.heroHeading,
                ...(mediumScreen ? mediumScreenStyles.heroHeading : {}),
              }}
            >
              {messages.heroHeading}
            </h2>
          </Col>
          <Col xs={12} md={6} lg={{ span: 4, offset: 1 }}>
            <h3
              className='font-medium primary-forest'
              style={{
                ...styles.heroDescription,
                ...(mediumScreen ? mediumScreenStyles.heroDescription : {}),
              }}
            >
              {/* TODO: Add referral code from endpoint */}
              {formatMessage.rich(messages.heroDescription, {
                referralCode: (
                  <span key='referralCode' style={styles.referralCode}>
                    {referralCode ?? ''}
                  </span>
                ),
              })}
            </h3>

            <div className='d-flex flex-row align-items-center pt-2 pb-4'>
              <Icon
                name='clockThree'
                className='pr-3 inline-block hero-clock-icon'
                style={mediumScreen ? mediumScreenStyles.heroClockIcon : {}}
              />
              <p
                className='font-semiBold primary-forest mb-0'
                style={{
                  ...styles.heroTime,
                  ...(mediumScreen ? mediumScreenStyles.heroTime : {}),
                }}
              >
                &nbsp;{messages.heroTime}
              </p>
            </div>
            <Button
              onClick={ctaClick}
              variant='primary'
              size='lg'
              className='hero-button mb-3'
              style={{
                ...styles.heroButton,
                ...(mediumScreen ? mediumScreenStyles.heroButton : {}),
              }}
            >
              {messages.heroButtonCTA}
            </Button>
          </Col>
        </Row>
        <Row></Row>
      </Container>
      <Image className='img-fluid w-100 d-md-none' src={MobileBG} alt='' />
      <Image className='img-fluid w-100 d-none d-md-block' src={DesktopBG} alt='' />
    </section>
  );
};

export default FooterHero;
