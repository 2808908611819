import React from 'react';

import Icon from '@components/Icon';
import colors from '@constants/colors';
import StyleSheet from '@styles/TypedStyleSheet';

interface MarkerProps {
  lat: number;
  lng: number;
}

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: -50,
    marginLeft: -20,
  },
  iconTypeContainer: {
    position: 'absolute',
    left: -13,
    top: -44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 28,
    width: 28,
    marginBottom: 12,
  },
});

// lat, lng props are required for PlantingMap marker functionality
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Marker = ({ lat, lng }: MarkerProps) => {
  return (
    <div style={styles.mainContainer}>
      <Icon name='pinOutline' fill={colors.darkGrass} width={40} strokeWidth={0} />
      <div style={styles.iconTypeContainer}>
        <Icon name='treeOutline' fill={colors.white} style={styles.icon} />
      </div>
    </div>
  );
};

export default Marker;
