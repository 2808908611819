const fonts = {
  light: 'Gilroy-Light',
  regular: 'Gilroy-Regular',
  medium: 'Gilroy-Medium',
  semiBold: 'Gilroy-SemiBold',
  bold: 'Gilroy-Bold',
  extraBold: 'Gilroy-ExtraBold',
} as const;

export default fonts;
