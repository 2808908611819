import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import React from 'react';

import PageLayout from '@components/PageLayout';
import UserImpact from '@pages/Impact/UserImpact';
import PageNotFound from '@pages/PageNotFound';
import IconsScreen from '@pages/Storyboard/IconsScreen';
import Storyboard from '@pages/Storyboard/Storyboard';
import ReactDOM from 'react-dom/client';
import FullStory from 'react-fullstory';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { QueryProvider } from './QueryContext';
import reportWebVitals from './reportWebVitals';

import './App.css';
import '@styles/buttons.css';
import '@styles/global.css';

// Reference here: https://github.com/remix-run/react-router/discussions/9553
const routes = [
  {
    path: 'impact/user/:id',
    element: <UserImpact />,
  },
];
const devRoutes = [
  {
    path: 'storyboard',
    element: <Storyboard />,
  },
  {
    path: 'storyboard/icons',
    element: <IconsScreen />,
  },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageLayout />,
    errorElement: process.env.NODE_ENV === 'development' ? undefined : <PageNotFound />,
    children: [
      {
        // the children follow the / route:
        children: [...routes, ...(process.env.NODE_ENV === 'development' ? devRoutes : [])],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID || ''} />
    <QueryProvider>
      <RouterProvider router={router} />
    </QueryProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
