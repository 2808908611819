import type { CSSProperties } from 'react';
import React from 'react';

import CardAnimalsGraphic from '@assets/images/stat-tiles/card-animals-graphic.jpg';
import CardAnimals from '@assets/images/stat-tiles/card-animals.jpg';
import CardPlasticGraphic from '@assets/images/stat-tiles/card-bottles-graphic.jpg';
import CardCarbon from '@assets/images/stat-tiles/card-carbon.jpg';
import CardCarbonGraphic from '@assets/images/stat-tiles/card-carbont-graphic.jpg';
import CardPlastic from '@assets/images/stat-tiles/card-plastic.jpg';
import CardTreesGraphic from '@assets/images/stat-tiles/card-trees-graphic.jpg';
import CardTrees from '@assets/images/stat-tiles/card-trees.jpg';
import Icon from '@components/Icon';
import StatTile from '@components/StatTile';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { Col, Row } from 'react-bootstrap';

const styles = StyleSheet.create({
  whiteContentText: {
    fontFamily: fonts.semiBold,
    color: colors.white,
  },
  whiteContentSubheadText: {
    fontFamily: fonts.medium,
    color: colors.white,
  },
  greenContentText: {
    fontFamily: fonts.semiBold,
    color: colors.forest,
  },
  plasticbankLogo: {
    marginTop: '5px',
  },
  wwfLogo: {
    verticalAlign: 'text-top',
    marginLeft: '5px',
  },
  tileRow: {
    display: 'flex',
    flex: 1,
  },
});
const largeScreenStyles = StyleSheet.create({
  contentText: {
    fontSize: '20px',
    lineHeight: '30px',
  },
  contentSubheadText: {
    fontSize: '16px',
    lineHeight: '18px',
    marginTop: '12px',
  },
  leftTile: {
    marginRight: 10,
  },
  rightTile: {
    marginLeft: 10,
  },
  topRow: {
    height: 170,
    marginBottom: 30,
  },
  bottomRow: {
    height: 170,
  },
});
const smallScreenStyles = StyleSheet.create({
  contentText: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  contentSubheadText: {
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '4px',
  },
  leftTile: {
    marginRight: 0,
  },
  rightTile: {
    marginLeft: 0,
  },
  topRow: {
    height: 100,
    marginBottom: 10,
  },
  bottomRow: {
    height: 100,
  },
});

const messages = {
  treeStatTitle: ` trees`,
  treeStatTitleSingle: ` tree`,
  treeStatSubtitle: `planted`,
  treeContent: {
    headline: `My trees were planted by `,
    subhead: `in biodiversity hotspots`,
  },
  carbonStatTitle: `t carbon`,
  carbonStatSubtitle: `absorbed`,
  carbonContent: {
    headline: `The total carbon I’ve captured over my trees’ lifetime.`,
  },
  plasticStatTitle: ` bottles`,
  plasticStatTitleSingle: ` bottle`,
  plasticStatSubtitle: `removed`,
  plasticContent: {
    headline: `My plastic bottles were removed by`,
  },
  animalStatTitle: ` animals`,
  animalStatTitleSingle: ` animal`,
  animalStatSubtitle: `adopted`,
  animalContent: {
    headline: `My animals were sponsored via `,
  },
};

interface ContentComponentProps {
  islargeScreen: boolean;
}

const TreeContent = ({ islargeScreen }: ContentComponentProps) => {
  return (
    <>
      <span
        style={{
          ...styles.whiteContentText,
          ...(islargeScreen ? largeScreenStyles.contentText : smallScreenStyles.contentText),
        }}
      >
        {messages.treeContent.headline}
        <Icon name='ecosia' fill='white' height={islargeScreen ? 21 : 10} />
      </span>
      <span
        style={{
          ...styles.whiteContentSubheadText,
          ...(islargeScreen
            ? largeScreenStyles.contentSubheadText
            : smallScreenStyles.contentSubheadText),
        }}
      >
        {messages.treeContent.subhead}
      </span>
    </>
  );
};

const CarbonContent = ({ islargeScreen }: ContentComponentProps) => {
  return (
    <span
      style={{
        ...styles.greenContentText,
        ...(islargeScreen ? largeScreenStyles.contentText : smallScreenStyles.contentText),
      }}
    >
      {messages.carbonContent.headline}
    </span>
  );
};

const PlasticContent = ({ islargeScreen }: ContentComponentProps) => {
  return (
    <>
      <span
        style={{
          ...styles.whiteContentText,
          ...(islargeScreen ? largeScreenStyles.contentText : smallScreenStyles.contentText),
        }}
      >
        {messages.plasticContent.headline}
      </span>
      <Icon
        name='plasticbank'
        fill='white'
        height={islargeScreen ? 40 : 24}
        style={styles.plasticbankLogo}
      />
    </>
  );
};

const AnimalContent = ({ islargeScreen }: ContentComponentProps) => {
  return (
    <span
      style={{
        ...styles.whiteContentText,
        ...(islargeScreen ? largeScreenStyles.contentText : smallScreenStyles.contentText),
      }}
    >
      {messages.animalContent.headline}
      <Icon name='wwf' fill='white' height={islargeScreen ? 70 : 28} style={styles.wwfLogo} />
    </span>
  );
};

export type ImpactStats = {
  trees: number;
  carbon: number;
  bottles: number;
  animals: number;
};

interface StatTilesCollectionProps {
  stats: ImpactStats;
  style?: CSSProperties;
}

function StatTilesCollection({ stats, style }: StatTilesCollectionProps) {
  const islargeScreen = useMediaQuery(`(min-width: ${breaks.large}px)`);
  return (
    <div style={{ ...style }}>
      <Row
        style={{
          ...styles.tileRow,
          ...(islargeScreen ? largeScreenStyles.topRow : smallScreenStyles.topRow),
        }}
      >
        <Col xs={6}>
          <StatTile
            statTitle={`${stats.trees}${
              stats.trees === 1 ? messages.treeStatTitleSingle : messages.treeStatTitle
            }`}
            statSubtitle={messages.treeStatSubtitle}
            statImage={CardTrees}
            revealContent={<TreeContent islargeScreen={islargeScreen} />}
            revealImage={CardTreesGraphic}
            style={islargeScreen ? largeScreenStyles.leftTile : smallScreenStyles.leftTile}
          />
        </Col>
        <Col xs={6}>
          <StatTile
            statTitle={`${stats.carbon}${messages.carbonStatTitle}`}
            statSubtitle={messages.carbonStatSubtitle}
            statImage={CardCarbon}
            revealContent={<CarbonContent islargeScreen={islargeScreen} />}
            revealImage={CardCarbonGraphic}
            style={islargeScreen ? largeScreenStyles.rightTile : smallScreenStyles.rightTile}
          />
        </Col>
      </Row>
      <Row
        style={{
          ...styles.tileRow,
          ...(islargeScreen ? largeScreenStyles.bottomRow : smallScreenStyles.bottomRow),
        }}
      >
        <Col xs={6}>
          <StatTile
            statTitle={`${stats.bottles}${
              stats.bottles === 1 ? messages.plasticStatTitleSingle : messages.plasticStatTitle
            }`}
            statSubtitle={messages.plasticStatSubtitle}
            statImage={CardPlastic}
            revealContent={<PlasticContent islargeScreen={islargeScreen} />}
            revealImage={CardPlasticGraphic}
            style={islargeScreen ? largeScreenStyles.leftTile : smallScreenStyles.leftTile}
          />
        </Col>
        <Col xs={6}>
          <StatTile
            statTitle={`${stats.animals}${
              stats.animals === 1 ? messages.animalStatTitleSingle : messages.animalStatTitle
            }`}
            statSubtitle={messages.animalStatSubtitle}
            statImage={CardAnimals}
            revealContent={<AnimalContent islargeScreen={islargeScreen} />}
            revealImage={CardAnimalsGraphic}
            style={islargeScreen ? largeScreenStyles.rightTile : smallScreenStyles.rightTile}
          />
        </Col>
      </Row>
    </div>
  );
}

export default StatTilesCollection;
