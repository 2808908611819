import type { CSSProperties, PropsWithChildren } from 'react';
import React from 'react';

import colors from '@constants/colors';
import StyleSheet from '@styles/TypedStyleSheet';

interface SquareButtonProps {
  size?: number;
  style?: CSSProperties;
  onClick?: () => void;
  selected?: boolean;
}

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 6,
    width: '45px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  deselected: {
    backgroundColor: colors.white,
    border: `2px solid ${colors.background.whisper}`,
  },
  selected: {
    backgroundColor: colors.success,
    border: `2px solid ${colors.success}`,
  },
});

const SquareButton = ({
  size,
  style,
  onClick,
  children,
  selected,
}: PropsWithChildren<SquareButtonProps>) => {
  return (
    <div
      style={{
        ...styles.mainContainer,
        ...(selected ? styles.selected : styles.deselected),
        ...(size ? { width: `${size}px`, height: `${size}px` } : {}),
        ...(style ?? {}),
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default SquareButton;
