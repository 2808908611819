import React from 'react';

import Icon from '@components/Icon';
import { Col, Container, Row } from 'react-bootstrap';

const messages = {
  title: 'Page Not Found',
  description: "The page you are looking for doesn't exist or has been moved.",
};

const styles = {
  title: {
    marginBottom: '10px',
    fontSize: '48px',
    lineHeight: '120%',
  },
  description: {
    fontSize: 20,
    lineHeight: '140%',
  },
  icon: {
    width: '100%',
    maxWidth: '300px',
    display: 'block',
  },
};

const PageNotFound: React.FC = () => {
  return (
    <Container className='mt-5' data-testid='pageNotFound_container'>
      <Row className='pb-5'>
        <Col
          xs={{ span: 8, offset: 2 }}
          sm={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
          className='text-center mt-5'
        >
          <div>
            <Icon name='pageNotFound' className='mx-auto' style={styles.icon} />
          </div>
          <div>
            <h1 className='darkForestGreen mt-4 px-xl-5' style={styles.title}>
              {messages.title}
            </h1>
            <p className='darkForestGreen font-medium px-xl-5' style={styles.description}>
              {messages.description}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
