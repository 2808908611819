import React from 'react';

import { AXIOS_INSTANCE } from '@api/mutator/client';
import type { AxiosError } from 'axios';
import * as AxiosLogger from 'axios-logger';
import { QueryClientProvider, QueryClient, setLogger } from 'react-query';

setLogger({
  log: console.log,
  warn: console.warn,
  error: (...error) => {
    if (
      // If a call gets cancelled, RQ errors that out--we don't need that logged
      error?.[0] &&
      'silent' in error[0]
    ) {
      return;
    }
    if (
      // This is expected on a few API calls, log that a call failed but don't need to error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error?.[0]?.message === 'Request failed with status code 404'
    ) {
      console.log(...error);
      return;
    }
    return console.error(...error);
  },
});

AxiosLogger.setGlobalConfig({
  dateFormat: 'HH:MM:ss',
  status: true,
  headers: true,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      onError(err) {
        const error = err as AxiosError<{ msg?: string }>;
        const statusCode = Number((error as AxiosError)?.response?.status);
        console.error(error, statusCode);
        // TODO: analytics
      },
      retry(failureCount, error) {
        // Client errors are not retried
        if ((error as AxiosError)?.response?.status?.toString().startsWith('4')) {
          return false;
        }
        // react-query does exponential back-off by default. Every non-client error gets 5 retries (1s, 2s, 4s, 8s, 16s)
        return failureCount <= 5;
      },
      // Stale time applies to cached queries that do NOT have a useQuery call on the focused page.
      // If we call useUser on a page, then leave that page to go to another one, the useUser call is considered stale.
      // If a later query calls useUser, it checks the stale time,
      // and if the cached stale response hasn't been checked since the stale time it'll fetch fresh.
      // Default stale time is 0, meaning it always refetches. This makes it so some time can pass before re-fetching
      staleTime: 10 * 1000, // 10 seconds
    },
    mutations: {
      onError(err) {
        const error = err as AxiosError<{ msg?: string }>;
        const statusCode = Number((error as AxiosError)?.response?.status);

        console.error(error, statusCode);
        // TODO: analytics
      },
      retry: false,
    },
  },
});

AXIOS_INSTANCE.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
});

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  },
);

export function QueryProvider(props: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const { children } = props;
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
