import React from 'react';

import ICONS_MAP from '@utils/iconsMap';

export type IconName = keyof typeof ICONS_MAP;

export type IconProps = {
  name: IconName;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  className?: string;
};

const Icon = ({
  name,
  width: widthProp,
  height: heightProp,
  style,
  className,
  ...SVGProps
}: IconProps & React.SVGProps<SVGSVGElement>) => {
  const icon = ICONS_MAP[name];
  const SvgIcon = icon.component;
  const aspectRatio = icon.width / icon.height;
  // If width or height are passed in directly, or in the style prop, use that
  // If only one dimension is passed in, use the original aspect ratio of the icon to calculate the other dimension
  // If neither is passed in, use the default width/height
  const passedInWidth = widthProp ?? (style ? style.width : undefined);
  const passedInHeight = heightProp ?? (style ? style.width : undefined);

  const width = passedInWidth ?? (heightProp ? heightProp * aspectRatio : icon.width);
  const height = passedInHeight ?? (widthProp ? widthProp / aspectRatio : icon.height);

  if (icon.hasNoColor && !SVGProps.fill && !SVGProps.stroke && !SVGProps.color) {
    console.log(`Icon ${name} will be invisible without ${icon.colorType} or color props`);
  }

  return (
    <SvgIcon
      width={width}
      height={height}
      className={`${className ?? ''}`}
      style={style}
      {...SVGProps}
    />
  );
};

export default Icon;
