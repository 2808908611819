import type { CSSProperties } from 'react';
import React from 'react';

import breaks from '@constants/breaks';
import colors from '@constants/colors';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { Col } from 'react-bootstrap';

type CarouselCardProp = {
  children?: React.ReactNode;
  testID?: string;
  customStyle?: CSSProperties;
  customClass?: string;
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.white,
    width: '120px',
    height: '50px',
    borderRadius: '6px',
    padding: '5px 10px',
    marginRight: '10px',
  },
});

const mediumScreenStyles = StyleSheet.create({
  card: {
    minWidth: '180px',
    height: '70px',
    padding: '10px 20px',
    marginRight: '20px',
  },
});

const largeScreenStyles = StyleSheet.create({
  card: {
    width: '199px',
    marginRight: '20px',
  },
});

const CarouselCard: React.FC<CarouselCardProp> = ({
  children,
  testID,
  customStyle,
  customClass,
}) => {
  const mediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  const largeScreen = useMediaQuery(`(min-width: ${breaks.large}px)`);

  return (
    <Col
      xs={1}
      lg={2}
      className={`d-flex justify-content-center align-items-center ${customClass ?? ''}`}
      data-testid={testID}
      style={{
        ...styles.card,
        ...(mediumScreen ? mediumScreenStyles.card : {}),
        ...(largeScreen ? largeScreenStyles.card : {}),
        ...customStyle,
      }}
    >
      {children ?? ''}
    </Col>
  );
};

export default CarouselCard;
