import type { CSSProperties } from 'react';
import React from 'react';

import WildlifeImg from '@assets/images/partners/wildlifeFederation.png';
import type { IconName } from '@components/Icon';
import Icon from '@components/Icon';
import StyleSheet from '@styles/TypedStyleSheet';
import { Image } from 'react-bootstrap';

import CarouselCard from './CarouselCard';
import './styles.css';

type CardIcons = {
  iconName: IconName;
  fill?: string;
  style?: CSSProperties;
};

const styles = StyleSheet.create({
  iconImg: {
    width: '35%',
  },
});

const cardIcons: Array<CardIcons> = [
  { iconName: 'ecosia', fill: '#377E25', style: { padding: '10px' } },
  { iconName: 'institutoEspinhaco', style: { padding: '2px 0px' } },
  { iconName: 'plasticbankColor' },
  { iconName: 'oceana' },
  { iconName: 'wwf' },
];

const ImpactCarousel: React.FC = () => {
  return (
    <div className='d-flex flex-nowrap flex-row carousel-row' data-testid='impactCarouselRow'>
      {cardIcons.map((icon) => {
        const { iconName, fill, style } = icon;
        return (
          <CarouselCard key={iconName} testID={iconName}>
            <Icon
              name={iconName}
              className='w-100 h-100'
              fill={fill ?? ''}
              style={style ?? undefined}
            />
          </CarouselCard>
        );
      })}
      <CarouselCard testID={'wildlifeImg'} customClass={'me-2'}>
        <Image src={WildlifeImg} className='py-2 d-block' style={styles.iconImg} />
      </CarouselCard>
    </div>
  );
};

export default ImpactCarousel;
