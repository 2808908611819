const colors = {
  white: '#FFFFFF',
  primaryBackground: '#F9F9F7',

  warning: '#FEC636',
  zest: '#C6EC76',
  lime: '#8ED66E',
  laurel: '#72937F',
  greenPea: '#3E7B56',
  amazon: '#386B4C',
  goblin: '#366649',

  primaryText: '#082223',

  robinEgg: '#069EC2',
  walk: '#329DA3',
  forest: '#0C5153',
  july: '#069EC2',
  success: '#00AE70',
  darkGrass: '#009262',
  ocean: '#007782',
  jungle: '#003F45',

  black: '#000000',

  background: {
    offWhite: '#F6F6F6',
    snowWhite: '#FAFAFA',
    sky: '#EBF9FB',
    whisper: '#EBEBEB',
    lighterGreen: '#E8FDF6',
  },

  border: {
    lightGray: '#F1F1F1',
  },

  text: {
    persimmon: '#FF5F4E',

    lightestGray: '#CCC',
    lighterGray: '#A6A6A6',
    gray: '#777777',

    stromboli: '#356447',
    mediumGray: '#3E3E3C',
    darkGray: '#3C3C3C',
    charcoal: '#1D1D1D',
    forest: '#0C5153',
    darkGreen: '#003F45',
  },
} as const;

// use https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4 to get opacity
export const hexOpacity = {
  twenty: '33',
  forty: '66',
  fifty: '80',
  seventy: 'B3',
};

export default colors;
