import React from 'react';

import { useListMapLocations } from '@api/sycamore';
import GoogleMap from 'google-maps-react-markers';

import Marker from './Marker';

const PlantingMap = () => {
  const { data: markers } = useListMapLocations();
  const defaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 3,
  };

  return (
    <div className='w-100 h-100' style={{ borderRadius: 8, overflow: 'hidden' }}>
      <GoogleMap
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        mapMinHeight='100%'
        options={{
          disableDefaultUI: true,
        }}
      >
        {markers?.locations?.map((location) => {
          const { latitude, longitude } = location.coordinates;
          if (!latitude || !longitude) return null;
          return <Marker key={location.title} lat={latitude} lng={longitude} />;
        })}
      </GoogleMap>
    </div>
  );
};

export default PlantingMap;
