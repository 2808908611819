import React from 'react';

import { useGetPublicProfile } from '@api/sycamore';
import FloatingBanner from '@components/FloatingBanner';
import Icon from '@components/Icon';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import { APP_STORE_URL, PLAY_STORE_URL } from '@constants/links';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import _ from 'lodash';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import './styles.css';

import { footerLinks, socialIcons, legal } from './content';
import FooterHero from './FooterHero';

interface FooterProps {
  ctaClick: () => void;
}

const messages = {
  smallChange: 'Small change. ',
  bigChange: 'Big change.',
  button: 'Sign up',
  copyright: 'Copyright',
};

const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: colors.forest,
    paddingBottom: '120px',
  },

  taglineBold: {
    fontFamily: fonts.semiBold,
  },
  footerLink: {
    textDecoration: 'none',
    fontFamily: fonts.medium,
    fontSize: '14px',
  },

  legalText: {
    fontFamily: fonts.regular,
    lineHeight: '14px',
    fontSize: '10px',
    color: colors.white,
    opacity: 0.5,
  },

  mainContainer: {
    paddingTop: '40px',
  },
  tagline: {
    fontSize: '20px',
  },

  copyright: {
    fontFamily: 'Gilroy-regular',
    fontSize: '10px',
  },

  footerBtn: {
    padding: '12px 35px',
    fontSize: '14px',
  },
  legalSection: {
    marginTop: '5px',
  },
});

const mediumScreenStyles = StyleSheet.create({
  tagline: {
    fontSize: '45px',
  },
  appBtn: {
    width: '100%',
    height: '50px',
  },
  treecardLogo: {
    width: '160px',
    height: '50px',
  },
  footerBtn: {
    width: '230px',
    height: '60px',
    padding: '9px 15px',
    fontSize: '20px',
  },
  copyright: {
    fontSize: '12px',
  },
});

const largeScreenStyles = StyleSheet.create({
  mainContainer: {
    paddingTop: '80px',
  },
  legalSection: {
    marginTop: '40px',
  },
  footerBtn: {
    minHeight: '60px !important',
  },
});

const chunkedFooterLinks = _.chunk(footerLinks, 4);
const getYear = () => new Date().getFullYear();

const Footer: React.FC<FooterProps> = ({ ctaClick }) => {
  const mediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  const largeScreen = useMediaQuery(`(min-width: ${breaks.large}px)`);
  const { id } = useParams();
  const { data: userData } = useGetPublicProfile(id ?? '');
  return (
    <footer>
      <FooterHero referralCode={userData?.referralCode ?? ''} ctaClick={ctaClick} />
      <section style={styles.sectionContainer}>
        <Container
          style={{
            ...styles.mainContainer,
            ...(largeScreen ? largeScreenStyles.mainContainer : {}),
          }}
        >
          <Row>
            <Col>
              <h3
                className='text-white pb-30 font-regular'
                style={{
                  ...styles.tagline,
                  ...(mediumScreen ? mediumScreenStyles.tagline : {}),
                }}
              >
                {messages.smallChange}
                <span className='font-semiBold'>{messages.bigChange}</span>
              </h3>
            </Col>
          </Row>
          <Row>
            {/* App store buttons */}
            <Col className='d-flex flex-row'>
              <a href={APP_STORE_URL} target='_blank' rel='noreferrer' className='mr-20'>
                <Icon name='appStore' style={mediumScreen ? mediumScreenStyles.appBtn : {}} />
              </a>
              <a href={PLAY_STORE_URL} target='_blank' rel='noreferrer'>
                <Icon name='googlePlay' style={mediumScreen ? mediumScreenStyles.appBtn : {}} />
              </a>
            </Col>
          </Row>
          <Row className='mt-30'>
            {/* Treecard logo (shown 768px and above) */}
            <Row className='d-none d-md-flex mb-4'>
              <Col md={6}>
                <Icon
                  name='treecardLongWhite'
                  style={mediumScreen ? mediumScreenStyles.treecardLogo : {}}
                />
              </Col>
            </Row>

            {/* Sign up button and social media icons */}
            <Col xs={12} md={6} lg={4} xl={3} className='mb-30'>
              <Row>
                <Col xs={6} md={12}>
                  <Button
                    onClick={ctaClick}
                    className='btn-inverted btn-medium footer-btn mb-md-3'
                    style={{
                      ...styles.footerBtn,
                      ...(mediumScreen ? mediumScreenStyles.footerBtn : {}),
                      ...(largeScreen ? largeScreenStyles.footerBtn : {}),
                    }}
                  >
                    {messages.button}
                  </Button>
                </Col>
                <Col
                  xs={6}
                  md={5}
                  lg={6}
                  className='d-flex flex-row justify-content-between align-items-center'
                >
                  {socialIcons.map((icon) => {
                    const { href, iconName, width } = icon;
                    return (
                      <a href={href} key={iconName} target='_blank' rel='noreferrer'>
                        <Icon name={iconName} width={width} fill={colors.white} />
                      </a>
                    );
                  })}
                </Col>
              </Row>
            </Col>

            {/* Navigation links */}
            <Col xs={12} md={6} lg={4} xl={3}>
              <Row>
                {chunkedFooterLinks.map((arr) => (
                  <Col md={5} xs={6} key={arr[0]?.name + '_arr'}>
                    {arr.map((link) => (
                      <a
                        href={link.href}
                        key={link.name}
                        className='d-block mb-20 text-white'
                        style={styles.footerLink}
                      >
                        {link.name}
                      </a>
                    ))}
                  </Col>
                ))}
              </Row>
            </Col>

            {/* Legal and copyright */}
            <Col
              lg={4}
              style={{
                ...styles.legalSection,
                ...(largeScreen ? largeScreenStyles.legalSection : {}),
              }}
            >
              <Col xs={12}>
                <p
                  className='text-white'
                  style={{
                    ...styles.copyright,
                    ...(mediumScreen ? mediumScreenStyles.copyright : {}),
                  }}
                >
                  {messages.copyright} &copy; {getYear()}
                </p>
              </Col>
              <Col md={7} lg={12}>
                {legal.map((item, index) => {
                  return (
                    <p key={index + 'legal'} className='text-uppercase' style={styles.legalText}>
                      {item}
                    </p>
                  );
                })}
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      <FloatingBanner userFirstName={userData?.firstName ?? ''} ctaClick={ctaClick} />
    </footer>
  );
};

export default Footer;
