/* eslint-disable max-len */
/**
 * Generated by update-icons-map script
 * Do not edit manually.
 * To add a new icon, place the SVG file in src/assets/svg or src/assets/svg/icons or src/assets/svg/actions or src/assets/svg/social or src/assets/svg/partners or src/assets/svg/logos and run "yarn update-icons-list".
 */

import { ReactComponent as appStore } from '@assets/svg/actions/app-store.svg';
import { ReactComponent as arrowRight } from '@assets/svg/actions/arrow-right.svg';
import { ReactComponent as downloadAppStore } from '@assets/svg/actions/download-app-store.svg';
import { ReactComponent as downloadGooglePlay } from '@assets/svg/actions/download-google-play.svg';
import { ReactComponent as googlePlay } from '@assets/svg/actions/google-play.svg';
import { ReactComponent as hamburgerMenu } from '@assets/svg/actions/hamburger-menu.svg';
import { ReactComponent as link } from '@assets/svg/actions/link.svg';
import { ReactComponent as mail } from '@assets/svg/actions/mail.svg';
import { ReactComponent as share } from '@assets/svg/actions/share.svg';
import { ReactComponent as clockThree } from '@assets/svg/clock-three.svg';
import { ReactComponent as evergreenTree } from '@assets/svg/icons/evergreen-tree.svg';
import { ReactComponent as pinOutline } from '@assets/svg/icons/pin-outline.svg';
import { ReactComponent as treeOutline } from '@assets/svg/icons/tree-outline.svg';
import { ReactComponent as treecardLongDark } from '@assets/svg/logos/treecard-long-dark.svg';
import { ReactComponent as treecardLongWhite } from '@assets/svg/logos/treecard-long-white.svg';
import { ReactComponent as pageNotFound } from '@assets/svg/page-not-found.svg';
import { ReactComponent as ecosia } from '@assets/svg/partners/ecosia.svg';
import { ReactComponent as institutoEspinhaco } from '@assets/svg/partners/instituto-espinhaco.svg';
import { ReactComponent as oceana } from '@assets/svg/partners/oceana.svg';
import { ReactComponent as plasticbankColor } from '@assets/svg/partners/plasticbank-color.svg';
import { ReactComponent as plasticbank } from '@assets/svg/partners/plasticbank.svg';
import { ReactComponent as wwf } from '@assets/svg/partners/wwf.svg';
import { ReactComponent as facebook } from '@assets/svg/social/facebook.svg';
import { ReactComponent as instagram } from '@assets/svg/social/instagram.svg';
import { ReactComponent as linkedin } from '@assets/svg/social/linkedin.svg';
import { ReactComponent as twitter } from '@assets/svg/social/twitter.svg';
import { ReactComponent as whatsapp } from '@assets/svg/social/whatsapp.svg';

/** This object is a mapping of all of the icons in src/assets/svg and src/assets/svg/icons and src/assets/svg/actions and src/assets/svg/social and src/assets/svg/partners and src/assets/svg/logos.
 *  Do not use this directly to display icons. Use the Icon component instead.
 */
const ICONS_MAP = {
  clockThree: {
    component: clockThree,
    width: 14,
    height: 14,
    hasNoColor: false,
    colorType: 'stroke',
  },
  pageNotFound: {
    component: pageNotFound,
    width: 47,
    height: 59,
    hasNoColor: false,
    colorType: 'stroke',
  },
  evergreenTree: {
    component: evergreenTree,
    width: 24,
    height: 30,
    hasNoColor: false,
    colorType: 'fill',
  },
  pinOutline: {
    component: pinOutline,
    width: 54,
    height: 69,
    hasNoColor: false,
    colorType: 'fill',
  },
  treeOutline: {
    component: treeOutline,
    width: 88,
    height: 88,
    hasNoColor: true,
    colorType: 'fill',
  },
  appStore: {
    component: appStore,
    width: 120,
    height: 40,
    hasNoColor: false,
    colorType: 'fill',
  },
  arrowRight: {
    component: arrowRight,
    width: 8,
    height: 14,
    hasNoColor: false,
    colorType: 'stroke',
  },
  downloadAppStore: {
    component: downloadAppStore,
    width: 118,
    height: 37,
    hasNoColor: false,
    colorType: 'fill',
  },
  downloadGooglePlay: {
    component: downloadGooglePlay,
    width: 154,
    height: 40,
    hasNoColor: false,
    colorType: 'fill',
  },
  googlePlay: {
    component: googlePlay,
    width: 136,
    height: 40,
    hasNoColor: false,
    colorType: 'stroke',
  },
  hamburgerMenu: {
    component: hamburgerMenu,
    width: 29,
    height: 20,
    hasNoColor: false,
    colorType: 'stroke',
  },
  link: {
    component: link,
    width: 25,
    height: 25,
    hasNoColor: false,
    colorType: 'fill',
  },
  mail: {
    component: mail,
    width: 30,
    height: 21,
    hasNoColor: false,
    colorType: 'stroke',
  },
  share: {
    component: share,
    width: 20,
    height: 28,
    hasNoColor: false,
    colorType: 'fill',
  },
  facebook: {
    component: facebook,
    width: 13,
    height: 22,
    hasNoColor: false,
    colorType: 'fill',
  },
  instagram: {
    component: instagram,
    width: 20,
    height: 19,
    hasNoColor: false,
    colorType: 'fill',
  },
  linkedin: {
    component: linkedin,
    width: 23,
    height: 22,
    hasNoColor: false,
    colorType: 'fill',
  },
  twitter: {
    component: twitter,
    width: 17,
    height: 15,
    hasNoColor: false,
    colorType: 'fill',
  },
  whatsapp: {
    component: whatsapp,
    width: 24.8,
    height: 24.8,
    hasNoColor: false,
    colorType: 'fill',
  },
  ecosia: {
    component: ecosia,
    width: 49,
    height: 12,
    hasNoColor: false,
    colorType: 'fill',
  },
  institutoEspinhaco: {
    component: institutoEspinhaco,
    width: 125,
    height: 51,
    hasNoColor: true,
    colorType: 'fill',
  },
  oceana: {
    component: oceana,
    width: 119,
    height: 25,
    hasNoColor: false,
    colorType: 'fill',
  },
  plasticbankColor: {
    component: plasticbankColor,
    width: 148,
    height: 34,
    hasNoColor: false,
    colorType: 'fill',
  },
  plasticbank: {
    component: plasticbank,
    width: 184,
    height: 43,
    hasNoColor: false,
    colorType: 'fill',
  },
  wwf: {
    component: wwf,
    width: 54,
    height: 80,
    hasNoColor: false,
    colorType: 'fill',
  },
  treecardLongDark: {
    component: treecardLongDark,
    width: 103,
    height: 30,
    hasNoColor: false,
    colorType: 'fill',
  },
  treecardLongWhite: {
    component: treecardLongWhite,
    width: 137,
    height: 40,
    hasNoColor: false,
    colorType: 'fill',
  },
} as const;

export default ICONS_MAP;
