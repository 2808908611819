import React from 'react';

import Icon from '@components/Icon';
import breaks from '@constants/breaks';
import {
  TREECARD_BLOG_URL,
  TREECARD_HOME_URL,
  TREECARD_IMPACT_URL,
  TREECARD_PETITIONS_URL,
  TREECARD_WALK_TO_PLANT_URL,
} from '@constants/links';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { Container, Button, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import './styles.css';

const messages = {
  mobileBtn: 'Sign up',
  desktopBtn: 'Get started',
  waysToPlant: 'Ways to plant',
  takeAction: 'Take action',
  whoWeAre: 'Who we are',
  blog: 'Blog',
};

const styles = StyleSheet.create({
  navBar: {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  signUpMobileBtnContainer: {
    marginLeft: 'auto',
    marginRight: '18px',
  },
  signUpMobileBtn: {
    padding: '10px 15px',
  },
  treecardLogo: {
    width: '88px',
  },
});

const largeScreenStyles = StyleSheet.create({
  navLinks: {
    fontSize: '16px',
    marginRight: '30px',
  },
  getStartedDesktopBtn: {
    padding: '14.5px 40px',
    fontSize: '16px',
  },
});

const TopNav: React.FC<{ ctaClick: () => void }> = ({ ctaClick }) => {
  const largeScreen = useMediaQuery(`(min-width: ${breaks.large}px)`);
  return (
    <Navbar
      bg='white'
      expand={'lg'}
      className='border-bottom'
      fixed='top'
      style={styles.navBar}
      role='navigation'
    >
      <Container className='pe-lg-0'>
        <Navbar.Brand href={TREECARD_HOME_URL} target='_blank' rel='noreferrer'>
          <Icon name='treecardLongDark' width={140} />
        </Navbar.Brand>

        <Nav.Item className='d-lg-none' style={styles.signUpMobileBtnContainer}>
          <Button variant='primary' style={styles.signUpMobileBtn} onClick={ctaClick}>
            {messages.mobileBtn}
          </Button>
        </Nav.Item>

        <Navbar.Toggle aria-controls='offcanvasNavbar-expand' className='border-0 p-0'>
          <Icon name='hamburgerMenu' />
        </Navbar.Toggle>

        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement='top'
          className='h-100'
        >
          <Container className='pe-0'>
            <Offcanvas.Header closeButton className='ps-0'>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                <Icon name='treecardLongDark' width={140} />
              </Offcanvas.Title>
            </Offcanvas.Header>
          </Container>
          <div className='border-bottom d-lg-none' />
          <Container className='pe-md-2 pe-lg-2'>
            <Offcanvas.Body className='pe-0 ps-0'>
              <Nav className='justify-content-end align-items-lg-center flex-grow-1 pe-3 pe-lg-0'>
                <Nav.Link
                  style={largeScreen ? largeScreenStyles.navLinks : {}}
                  href={TREECARD_WALK_TO_PLANT_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  {messages.waysToPlant}
                </Nav.Link>
                <Nav.Link
                  style={largeScreen ? largeScreenStyles.navLinks : {}}
                  href={TREECARD_PETITIONS_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  {messages.takeAction}
                </Nav.Link>
                <Nav.Link
                  style={largeScreen ? largeScreenStyles.navLinks : {}}
                  href={TREECARD_IMPACT_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  {messages.whoWeAre}
                </Nav.Link>
                <Nav.Link
                  style={largeScreen ? largeScreenStyles.navLinks : {}}
                  href={TREECARD_BLOG_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  {messages.blog}
                </Nav.Link>
                <Nav.Item className='d-none d-lg-inline-block'>
                  <Button
                    variant='primary'
                    style={largeScreenStyles.getStartedDesktopBtn}
                    onClick={ctaClick}
                  >
                    {messages.desktopBtn}
                  </Button>
                </Nav.Item>
              </Nav>
            </Offcanvas.Body>
          </Container>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default TopNav;
