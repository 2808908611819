import React from 'react';

import breaks from '@constants/breaks';
import colors from '@constants/colors';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import formatMessage from 'format-message';
import { Col, Container, Row, Button } from 'react-bootstrap';

import Icon from './Icon';

interface FloatingBannerProps {
  userFirstName: string;
  ctaClick: () => void;
}

const messages = {
  title: 'Join {userFirstName} on Treecard',
  subtext: 'Earn a free tree when you sign up!',
  button: 'Sign up',
  nameDefault: 'me',
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    bottom: '20px',
    left: '0',
    right: '0',
  },
  mobileClickLayer: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
    top: '0',
    cursor: 'pointer',
  },
  banner: {
    backgroundColor: colors.success,
    borderRadius: '8px',
    boxShadow: '0px 5px 16px rgba(0,0,0,.25)',
    padding: '0px 25px',
    minHeight: '70px',
  },
  title: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '8px',
  },
  subtext: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  treeIcon: {
    marginRight: '10px',
  },
});

const xSmallScreenStyles = StyleSheet.create({
  banner: {
    padding: '0px 10px',
  },
});

const mediumScreenStyles = StyleSheet.create({
  container: {
    bottom: '30px',
  },
  banner: {
    height: '100px',
    maxWidth: '600px',
    padding: '15px 25px',
  },
  title: {
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '10px',
  },
  subtext: {
    fontSize: '18px',
    lineHeight: '21px',
  },
  treeIcon: {
    marginRight: '20px',
    width: '35px',
    height: '45px',
  },
});

const FloatingBanner: React.FC<FloatingBannerProps> = ({ ctaClick, userFirstName }) => {
  const xSmallScreen = useMediaQuery(`(max-width: ${breaks.mobileXSmall}px)`);
  const mediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);

  return (
    <Container
      style={{ ...styles.container, ...(mediumScreen ? mediumScreenStyles.container : {}) }}
    >
      <Row>
        <Col xs={12}>
          <div onClick={ctaClick} style={styles.mobileClickLayer} className='d-md-none' />
          <div
            style={{
              ...styles.banner,
              ...(xSmallScreen ? xSmallScreenStyles.banner : {}),
              ...(mediumScreen ? mediumScreenStyles.banner : {}),
            }}
            className='d-flex flex-row align-items-center justify-content-between mx-auto'
          >
            <div className='d-flex flex-row align-items-center'>
              <Icon
                style={{ ...styles.treeIcon, ...(mediumScreen ? mediumScreenStyles.treeIcon : {}) }}
                name='evergreenTree'
              />
              <div>
                <p
                  className='text-white font-semiBold'
                  style={{ ...styles.title, ...(mediumScreen ? mediumScreenStyles.title : {}) }}
                >
                  {formatMessage.rich(messages.title, {
                    userFirstName: (
                      <span key='userFirstName'>{userFirstName ?? messages.nameDefault}</span>
                    ),
                  })}
                </p>
                <p
                  className='mb-0 text-white font-medium'
                  style={{ ...styles.subtext, ...(mediumScreen ? mediumScreenStyles.subtext : {}) }}
                >
                  {messages.subtext}
                </p>
              </div>
            </div>
            <div>
              <Button className='d-none d-md-block' variant='inverted' size='sm' onClick={ctaClick}>
                {messages.button}
              </Button>
              <Icon name='arrowRight' width={10} height={15} className='d-md-none' />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FloatingBanner;
