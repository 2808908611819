import React from 'react';

import type { ImpactStats } from '@components/StatTilesCollection';
import StatTilesCollection from '@components/StatTilesCollection';
import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { Col, Row } from 'react-bootstrap';

import NameHeader from './NameHeader';

interface StatRowProps {
  firstName: string;
  lastName: string;
  memberSince: Date | undefined;
  userStats: ImpactStats;
  tvImageUrl: string | undefined;
}

const styles = StyleSheet.create({
  subtitle: {
    fontFamily: fonts.semiBold,
    color: colors.text.lighterGray,
  },
  tvImageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tvImage: {
    width: '100%',
    backgroundSize: 'cover',
    borderRadius: 8,
  },
});
const mediumScreenStyles = StyleSheet.create({
  statTiles: {
    marginTop: '30px',
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '20.88px',
    marginBottom: '30px',
  },
  tvImage: {
    flex: 1,
    height: '100%',
  },
});
const smallScreenStyles = StyleSheet.create({
  statTiles: {
    marginTop: '20px',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '16.24px',
    marginBottom: '15px',
  },
  tvImage: {
    height: '285px',
  },
});

const messages = {
  forest: `'s forest`,
};

const StatRow = ({ firstName, lastName, memberSince, userStats, tvImageUrl }: StatRowProps) => {
  const isMediumScreen = useMediaQuery(`(min-width: ${breaks.medium}px)`);
  const userFullName = `${firstName} ${lastName}`;
  return (
    <Row className={`gx-5 overflow-hidden ${isMediumScreen ? 'mt-50' : ''}`} data-testid='StatRow'>
      <Col xs={12} md={6}>
        <NameHeader
          userName={userFullName}
          memberSince={memberSince}
          urlToShare={new URL(window.location.href)}
        />
        <StatTilesCollection
          stats={userStats}
          style={isMediumScreen ? mediumScreenStyles.statTiles : smallScreenStyles.statTiles}
        />
      </Col>
      <Col xs={12} md={6} className={isMediumScreen ? '' : 'mt-30'}>
        <div style={styles.tvImageContainer}>
          <span
            style={{
              ...styles.subtitle,
              ...(isMediumScreen ? mediumScreenStyles.subtitle : smallScreenStyles.subtitle),
            }}
          >{`${firstName}${messages.forest}`}</span>
          {tvImageUrl && tvImageUrl !== '' ? (
            <div
              style={{
                ...styles.tvImage,
                ...(isMediumScreen ? mediumScreenStyles.tvImage : smallScreenStyles.tvImage),
                backgroundImage: `url('${tvImageUrl}')`,
              }}
            />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default StatRow;
