import React from 'react';

import type { IconName } from '@components/Icon';
import Icon from '@components/Icon';
import ICONS_MAP from '@utils/iconsMap';
import { Col, Container, Row } from 'react-bootstrap';

type IconEntry = [
  string,
  {
    width: number;
    height: number;
    hasNoColor: boolean;
    colorType: 'fill' | 'stroke';
  },
];

const iconEntries: IconEntry[] = Object.entries(ICONS_MAP);

const styles = {
  iconContainer: {
    borderRadius: 6,
    backgroundColor: '#EEE',
    borderColor: '#AAA',
    borderWidth: 1,
  },
};

const UIStoryboardIcons = () => {
  return (
    <Container className='mt-5'>
      <Row>
        {iconEntries.length ? (
          iconEntries.map(([key, icon]) => {
            return (
              <Col
                sm={1}
                className='px-2 pt-3 m-2 text-center'
                style={styles.iconContainer}
                key={key}
              >
                <Icon
                  name={key as IconName}
                  width={32}
                  height={32}
                  {...(icon.hasNoColor ? { [icon.colorType]: '#000' } : {})}
                />
                <p className='mt-1 small'>{key}</p>
              </Col>
            );
          })
        ) : (
          <p>No icons found</p>
        )}
      </Row>
    </Container>
  );
};

export default UIStoryboardIcons;
