import type { CSSProperties, ReactNode } from 'react';
import React, { useState } from 'react';

import breaks from '@constants/breaks';
import colors from '@constants/colors';
import fonts from '@constants/fonts';
import useMediaQuery from '@hooks/useMediaQuery';
import StyleSheet from '@styles/TypedStyleSheet';
import { isDesktop } from 'react-device-detect';

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    position: 'relative',
  },
  statCard: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    borderRadius: 8,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    transition: 'opacity 500ms',
  },
  graphicCard: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    borderRadius: 8,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  statTitle: {
    fontFamily: fonts.semiBold,
    color: colors.jungle,
  },
  statSubtitle: {
    fontFamily: fonts.semiBold,
    color: colors.text.lighterGray,
  },
});

const smallScreenStyles = StyleSheet.create({
  statCard: {
    padding: 15,
  },
  graphicCard: {
    padding: 15,
  },
  statTitle: {
    fontSize: '18px',
    lineHeight: '20.88px',
  },
  statSubtitle: {
    fontSize: '12px',
    lineHeight: '14.32px',
  },
});

const largeScreenStyles = StyleSheet.create({
  statCard: {
    padding: 20,
  },
  graphicCard: {
    padding: 20,
  },
  statTitle: {
    fontSize: '28px',
    lineHeight: '32.48px',
  },
  statSubtitle: {
    fontSize: '16px',
    lineHeight: '19.09px',
  },
});

interface StatTileProps {
  statTitle: string;
  statSubtitle: string;
  revealContent: ReactNode;
  statImage: string;
  revealImage: string;
  style?: CSSProperties;
}

const StatTile = ({
  statTitle,
  statSubtitle,
  revealContent,
  statImage,
  revealImage,
  style,
}: StatTileProps) => {
  const [opacity, setOpacity] = useState(100);
  const [selected, setSelected] = useState(false);
  const largeScreen = useMediaQuery(`(min-width: ${breaks.large}px)`);

  const select = () => {
    setSelected(true);
    setOpacity(0);
  };

  const deSelect = () => {
    setSelected(false);
    setOpacity(100);
  };

  const toggleSelected = () => {
    if (selected) {
      deSelect();
    } else {
      select();
    }
  };

  return (
    <div
      style={{ ...styles.mainContainer, ...style }}
      onMouseEnter={isDesktop ? select : undefined}
      onMouseLeave={deSelect}
      onClick={toggleSelected}
      onBlur={deSelect}
    >
      <div
        style={{
          ...styles.graphicCard,
          ...(largeScreen ? largeScreenStyles.graphicCard : smallScreenStyles.graphicCard),
          backgroundImage: `url(${revealImage})`,
        }}
      >
        {revealContent}
      </div>
      <div
        style={{
          ...styles.statCard,
          ...(largeScreen ? largeScreenStyles.statCard : smallScreenStyles.statCard),
          backgroundImage: `url(${statImage})`,
          opacity: opacity,
        }}
      >
        <span
          style={{
            ...styles.statTitle,
            ...(largeScreen ? largeScreenStyles.statTitle : smallScreenStyles.statTitle),
          }}
        >
          {statTitle}
        </span>
        <span
          style={{
            ...styles.statSubtitle,
            ...(largeScreen ? largeScreenStyles.statSubtitle : smallScreenStyles.statSubtitle),
          }}
        >
          {statSubtitle}
        </span>
      </div>
    </div>
  );
};

export default StatTile;
