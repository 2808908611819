/* eslint-disable max-len */

import type { IconName } from '@components/Icon';
import {
  TWITTER_URL,
  LINKEDIN_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TREECARD_HOME_URL,
  TREECARD_MISSION_URL,
  TREECARD_IMPACT_URL,
  TREECARD_APP_URL,
  TREECARD_PRIVACY_URL,
  TREECARD_HOW_IT_WORKS_URL,
  TREECARD_CONTACT_URL,
} from '@constants/links';

type SocialIcons = {
  iconName: IconName;
  href: string;
  width: number;
};

type FooterLinks = {
  name: string;
  href: string;
};

export const socialIcons: Array<SocialIcons> = [
  { iconName: 'twitter', href: TWITTER_URL, width: 24 },
  { iconName: 'linkedin', href: LINKEDIN_URL, width: 24 },
  { iconName: 'facebook', href: FACEBOOK_URL, width: 14 },
  { iconName: 'instagram', href: INSTAGRAM_URL, width: 24 },
];

export const footerLinks: Array<FooterLinks> = [
  { name: 'Home', href: TREECARD_HOME_URL },
  { name: 'Benefits', href: TREECARD_MISSION_URL },
  { name: 'Who we are', href: TREECARD_IMPACT_URL },
  { name: 'Get the app', href: TREECARD_APP_URL },
  {
    name: 'Privacy policy',
    href: TREECARD_PRIVACY_URL,
  },
  { name: 'Ecosia', href: TREECARD_HOW_IT_WORKS_URL },
  { name: 'Contact us', href: TREECARD_CONTACT_URL },
];

export const legal: Array<string> = [
  'This website is operated by Treecard Inc. Treecard reserves the right to restrict or revoke any and all offers at any time. The Treecard® Mastercard® debit Card is issued by Sutton Bank, Member FDIC, pursuant to a license from Mastercard International Incorporated.',
  '1.  Data pulled from Chase Bank Account Details as of Sept. 5th, 2022. https://www.chase.com/personal/checking/total-checking',
  '2. Data pulled from Wells Fargo Bank Account Details as of Sept. 5th, 2022:https://www.wellsfargo.com/checking/everyday',
];
